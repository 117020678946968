import styled from "styled-components";
import { MdVerified } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";

export const HeadingContainer = styled.div`
  margin-top: 120px;
  display: flex;
  align-items: flex-end;
  gap: 2px;
  padding: 20px;
  margin-left: 20px;
`

export const BrokerNameContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`

export const IndexNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const BrokerHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
`

export const Heading = styled.div`
  font-size: 24px;
`
export const SubHeading = styled.span`
  font-weight: 500;
`

export const Container = styled.form`
  margin: 20px;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
  padding: 10px 0px 1rem 20px;
  flex-wrap: wrap;
  gap: 10px;
`

export const InputTextWrapper = styled.div`
  width: 280px;
  height: 30px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  /* margin-bottom: 40px; */
`

export const SymbolInputTextWrapper = styled.div`
  width: 200px;
  height: 30px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
`

export const InputTextWrapperText = styled.p`
  padding-left: 5px;
  margin-bottom: 0px !important;
  font-size: 13px !important;
  width: 100%;
  overflow: hidden;
`

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 8px;
`;

export const InputWrapperInner = styled.div`
  
`

export const InputValidTextWrapperText = styled.p`
  padding-left: 5px;
  color: ${props => props.isValid ? 'green' : 'red'}
`

export const InputWrapper = styled.div`
  width: 280px;
  margin-bottom: 10px;
`

export const InputText = styled.h5`
  font-size: 13px;
  color: #000000;
  margin-bottom: 10px;
  letter-spacing: 0.8px;
`

export const SymbolHeaderText = styled.h5`
  font-size: 16px;
  color: #000000;
  margin-bottom: 0px !important;
  `
export const InputContainer = styled.div`
width: 100%;
height: 40px;
display: flex;
align-items: center;
background-color: whitesmoke;
overflow: hidden;
cursor: pointer;
border: 1px solid #ccc;
/* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075); */
`;

export const Border = styled.div`
  height: 40%;
  width: 1.3px;
  background-color: rgb(223, 223, 223);
`;

export const EyeButton = styled.button`
  padding: 0px 15px 0px 12px;
  border: none;
  background-color: transparent;
  height: 40px;
  cursor: pointer;
  transition-duration: 0.3s;

  &:hover {
    background-color: rgb(255, 230, 230);
    transition-duration: 0.3s;
  }
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 27px;
  padding: 10px 10px;
  font-size: 16px;
  background-color: #1e1e1e;
  color: white;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const EditIcon = styled(FaRegEdit)`
  vertical-align: middle;
  margin-right: 5px;
`

export const SymbolInputText = styled.h5`
  margin-bottom: 0px !important;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.8px;
`

export const Input = styled.input`
  width: 100%;
  height: 30px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #000000;
  font-size: 13px;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
`

export const Select = styled.select`
  width: 95%;
  height: 40px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #000000;
  font-size: 13px;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
`

export const SymbolInput = styled.input`
  width: 100%;
  height: 30px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #000000;
  font-size: 13px !important;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
`

export const ChipInput = styled.input`
  background-color: #f9f9f9;
  color: #000000;
  outline: none;
  white-space: nowrap;
  padding-left: 5px;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 64px);
  align-items: center;
`


export const ValidIcon = styled(MdVerified)`
  width: 25px;
  height: 25px;
  color: green;
`

export const UnValidIcon = styled(MdCancel)`
  width: 25px;
  height: 25px;
  color: red;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
`

export const TextWrapper = styled.div`
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
`

export const StatusWrapper = styled.div`
  height: 70px;
  
`

export const ValidateButtonWrapper = styled.div`
  height: 70px;
  display: flex;
  align-items: flex-end; 
`