import * as React from "react";
import * as style from "./style";
import { useSelector, useDispatch } from "react-redux";
import { closeToast, forceClose } from "../../redux/slice/toastSlice";
import { Toast } from "react-bootstrap";
import { Alert, Snackbar } from "@mui/material";

const AlertSnackBar = () => {
  const dispatch = useDispatch();

  const { isOpen, message, type, autoHide } = useSelector((state) => state.toast);

  const handleClose = () => {
    dispatch(forceClose());
  };

  return (
    <>
      {/* <style.TostBar
        position="top-center"
        className="p-3"
      >
        <Toast
          bg={type === "error" ? "danger" : "success"}
          onClose={handleClose}
          show={isOpen}
          delay={5000}
          autohide={autoHide}
        >
          <Toast.Body className="text-white">{message} </Toast.Body>
        </Toast>
      </style.TostBar> */}
      <Snackbar sx={{ marginTop: '6rem', zIndex: "9999999" }} open={isOpen} autoHideDuration={autoHide ? 5000 : null} onClose={handleClose} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} >
        <Alert variant="filled" color={type} severity={type} onClose={handleClose}>{message}</Alert>
      </Snackbar>
    </>
  );
};

export default AlertSnackBar;
