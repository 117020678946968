import { Fragment, createContext } from "react";
import io from "socket.io-client";
import config from "../config";

export const SockContext = createContext({ socket: null });

const SockContextWrapper = ({ children }) => {
  const socket = io(config.SOCKET_URL);

  if (socket) {
    return (
      <SockContext.Provider value={{ socket }}>{children}</SockContext.Provider>
    );
  } else {
    return <Fragment>{children}</Fragment>;
  }
};

export default SockContextWrapper;
