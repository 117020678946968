import {Outlet, Navigate } from "react-router-dom";
import { getAuthToken } from "../utils/storage"

const FreeRoutes = () => {

    const data = getAuthToken({ isSession: true });
    
    return data ? <Navigate to="/dashboard" /> : <Outlet />;
}

export default FreeRoutes;