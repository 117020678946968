import * as React from "react";
import * as style from "./style";
import { getAllClient, updateUserStatus } from "../../services/admin.service";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/loader/CustomLoader";
import { Modal, Button, Form, Spinner, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { openToast } from "../../redux/slice/toastSlice";
import { FaHome } from "react-icons/fa";
import DraggableModal from "../../components/draggableModal/DraggableModal";

const AllUsers = () => {
  const [clientData, setClientData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [userStatus, setUserStatus] = React.useState("");
  const [currentRow, setCurrentRow] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const selector = useSelector((user) => user.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      id: "name",
      label: "User Name",
      minWidth: 100,
      align: "left",
      tableHead: "left",
    },
    {
      id: "email",
      label: `${t("clientTable.column2")}`,
      minWidth: 100,
      align: "right",
      tableHead: "center",
    },
    {
      id: "type",
      label: "Type",
      minWidth: 100,
      align: "center",
      tableHead: "center",
    },
    {
      id: "status",
      label: "Status",
      minWidth: 100,
      align: "center",
      tableHead: "center",
    },
  ];

  const createData = (id, name, email, type, status) => {
    return { id, name, email, type, status };
  };

  const handleEditStatus = React.useCallback((event, row) => {
    setUserStatus(row.status);
    setCurrentRow(row);
    setShowModal(true);
  }, []);

  const handleSubmit = async (event) => {
    setLoader(true);
    event.preventDefault();
    const { success, message } = await updateUserStatus(currentRow?.id, {
      status: userStatus,
    });

    if (success) {
      setLoader(false);
      setShowModal(false);
      const clientResponse = await getAllClient();
      setClientData(clientResponse?.result);
    } else {
      setLoader(false);
      setShowModal(false);
      dispatch(openToast({ message: message, type: "error" }));
    }
  };

  const rows = React.useMemo(() => {
    const newRows = [];
    (clientData || [])?.forEach((user) => {
      let row = createData(
        user?._id,
        user?.name,
        user?.email,
        user?.type,
        user?.status
      );
      newRows.push(row);
    });
    return newRows;
  }, [clientData]);

  React.useEffect(() => {
    const fetchData = async () => {
      const clientResponse = await getAllClient();
      setClientData(clientResponse?.result || []);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handelEditType = (event, row) => {
    setCurrentRow(row);
  };

  const addUser = () => {
    navigate("/add-user");
  };

  const handelClickHome = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <style.Container>
        <style.HeaderWrapper>
          <div>
            <style.Heading>User Profiles</style.Heading>
          </div>
          <div className="flex gap-2 items-center">
            <button
              className="flex items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
              onClick={handelClickHome}
            >
              <FaHome className="mr-1 align-middle" />
              Home
            </button>
            {selector?.type === "admin" ? (
              <button
                className="flex items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
                onClick={addUser}
              >
                <style.PlusIcon className="mr-1 align-middle" />
                Add User
              </button>
            ) : null}
          </div>
        </style.HeaderWrapper>

        <div>
          {loading ? (
            <style.LoaderWrapper>
              <CustomLoader />
            </style.LoaderWrapper>
          ) : (
            <style.TableWrapper>
              <div className="ps-cl-container">
                <table className="margin-table">
                  <thead className="margin-thead table-head">
                    <tr className="margin-tr">
                      {columns.map((column, index) => (
                        <th
                          className="table-head margin-th"
                          key={index}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.tableHead,
                          }}
                        >
                          {column.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="margin-tbody">
                    {rows?.map((row, index) => (
                      <tr key={index} className="margin-tr">
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <td
                              key={column.id}
                              className="whitespace-nowrap p-2 all-normal-text"
                              align={column.align}
                            >
                              {column.id === "status" ? (
                                <div>
                                  {value?.charAt(0)?.toUpperCase() +
                                    value?.slice(1)}{" "}
                                  <style.PenIcon
                                    onClick={(e) => handleEditStatus(e, row)}
                                  />
                                </div>
                              ) : column.id === "client" ? (
                                <div>
                                  {value}{" "}
                                  <style.PenIcon
                                    onClick={(e) => handelEditType(e, row)}
                                  />
                                </div>
                              ) : column.id === "type" ? (
                                <div>
                                  {value?.charAt(0)?.toUpperCase() +
                                    value?.slice(1)}{" "}
                                </div>
                              ) : column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : (
                                value
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </style.TableWrapper>
          )}
        </div>
      </style.Container>

      <DraggableModal
        title="Edit User Status"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Status</Form.Label>
              <Form.Select
                className="all-normal-text"
                onChange={(e) => setUserStatus(e.target.value)}
              >
                <option className="all-normal-text" value={userStatus}>
                  Select Status
                </option>
                <option className="all-normal-text" value={"enabled"}>
                  Enable
                </option>
                <option className="all-normal-text" value={"disabled"}>
                  Disable
                </option>
                <option className="all-normal-text" value={"removed"}>
                  Remove
                </option>
              </Form.Select>
            </Form.Group>
            <div className="flex gap-2 mt-3 justify-center">
              <Button className="button-style" variant="primary" type="submit">
                {loader ? <Spinner size="sm" /> : "Update"}
              </Button>
              <Button
                className="button-style"
                variant="secondary"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </DraggableModal>
    </>
  );
};

export default AllUsers;
