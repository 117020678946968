import React, { Fragment, useCallback, useEffect, useState } from "react";
import NavBar from "../../components/navbar/NavBar";
import CustomLoader from "../../components/loader/CustomLoader";
import isEmpty from "is-empty";
import MarginDisplay from "../../components/margins/MarginDisplay";
import toastSlice from "../../redux/slice/toastSlice";
import { useDispatch } from "react-redux";
import { getMargins } from "../../services/admin.service";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const MarginInfo = () => {
  const [loading, setLoading] = useState(false);
  const [margins, setMargins] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchMarginData = useCallback(async () => {
    try {
      setLoading(true);
      const { success, result, message } = await getMargins();
      setLoading(false);
      if (success) {
        setMargins(result);
      } else {
        dispatch(toastSlice({ message, type: "error" }));
      }
    } catch (error) {
      setLoading(false);
      dispatch(toastSlice({ message: "Something went wrong", type: "error" }));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchMarginData();
  }, [fetchMarginData]);

  return (
    <Fragment>
      <div className={`ps-container container ${loading && "ps-load"}`}>
        {loading ? (
          <CustomLoader />
        ) : (
          <Fragment>
            {isEmpty(margins) ? (
              <div className="ps-load">No margin data available</div>
            ) : (
              <Fragment>
                <div className="mt-3 mb-3 d-flex justify-content-between align-items-center">
                  <div>Clients Margin Info</div>
                  <button
                    className="flex items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
                    onClick={() => navigate("/dashboard")}
                  >
                    <FaHome className="mr-1 align-middle" />
                    Home
                  </button>
                </div>
                <MarginDisplay data={margins} />
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default MarginInfo;
