import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { openToast } from "../../../redux/slice/toastSlice";
import isEmpty from "is-empty";
import {
  postSquareOff,
  postReOrder,
  getAllIndexData,
} from "../../../services/admin.service";
import DraggableModal from "../../draggableModal/DraggableModal";

const SquareOff = ({
  show,
  onHide,
  user,
  order,
  brokerage,
  fetchPositionsData,
  mode,
  groupId,
}) => {
  const dispatch = useDispatch();
  const [sqoffQty, setSqoffQty] = useState(0);
  const [reOrderQty, setReOrderQty] = useState(0);
  const [index, setIndex] = useState([]);
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleSquareOff = async () => {
    try {
      setSubmitLoader(true);
      if (isEmpty(order) || isEmpty(brokerage) || isEmpty(user)) {
        dispatch(
          openToast({
            message: "Some error occurred",
            type: "error",
          })
        );
        setSubmitLoader(false);
      }

      const requestData = {
        userId: user?._id,
        user_id: user?._id,
        broker: brokerage,
        order,
        squareOffQty:
          mode === "all"
            ? order.quantity
            : mode === "reOrder"
            ? reOrderQty
            : sqoffQty,
        group_id: groupId,
      };
      
      let response;
      if (mode === "reOrder") {
        response = await postReOrder(requestData);
        console.log("response: ", response);
      }
      if (mode === "mono") {
        response = await postSquareOff(requestData);
        console.log("response: ", response);
      }
      if (response.success) {
        setSubmitLoader(false);
        // dispatch(
        //   openToast({
        //     message: message,
        //     type: "success",
        //   })
        // );
        setSqoffQty("");
        await fetchPositionsData();
      } else {
        setSubmitLoader(false);
        dispatch(
          openToast({
            message: response.message,
            type: "error",
          })
        );
      }
    } catch (error) {
      setSubmitLoader(false);
      dispatch(
        openToast({
          message: "Some error occurred",
          type: "error",
        })
      );
    }
  };

  const filteredIndex = index?.find((item) => {
    return Object.values(item?.symbols).includes(order?.stock_code);
  });

  useEffect(() => {
    setSqoffQty(parseInt(order?.quantity));
    setReOrderQty(parseInt(order?.quantity));

    const fetchData = async () => {
      const response = await getAllIndexData();
      setIndex(response?.result);
    };
    fetchData();
  }, [order?.quantity]);

  return (
    <DraggableModal
      show={show}
      onHide={onHide}
      title={`${user?.name} - ${
        brokerage === "icici"
          ? "ICICI DIRECT"
          : brokerage.charAt(0).toUpperCase() + brokerage.slice(1)
      }`}
    >
      <Container>
        <Row className="mb-3">
          <Col xs={5}>Contract:</Col>
          <Col style={{ padding: "0px" }} xs={7}>
            {order?.contract}
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={5}>Quantity:</Col>
          <Col style={{ padding: "0px" }} xs={7}>
            {order?.quantity}
          </Col>
        </Row>

        {mode === "reOrder" && (
          <Row className="mb-3">
            <Col xs={5}>Position:</Col>
            <Col style={{ padding: "0px" }} xs={7}>
              {order?.action}
            </Col>
          </Row>
        )}

        <Row className="flex items-center">
          <Col xs={5}>
            {mode === "reOrder" ? "Re-Order Qty:" : "Square Off Qty:"}
          </Col>
          <Col style={{ padding: "0px" }} xs={7}>
            <div className="w-7/12">
              <InputGroup className="w-100">
                {mode !== "all" && (
                  <Button
                    size="sm"
                    variant="secondary"
                    className="boot-btn"
                    onClick={() => {
                      if (mode === "reOrder") {
                        setReOrderQty((prev) => {
                          return parseInt(prev || 0) - filteredIndex?.lot_size;
                        });
                      } else {
                        setSqoffQty((prev) => {
                          if (prev - 1 < filteredIndex?.lot_size) {
                            return filteredIndex?.lot_size;
                          }
                          return parseInt(prev || 0) - filteredIndex?.lot_size;
                        });
                      }
                    }}
                  >
                    <span className="bi bi-dash" />
                  </Button>
                )}

                <Form.Control
                  size="sm"
                  type="number"
                  name="quantity"
                  value={
                    mode === "all"
                      ? order?.quantity
                      : mode === "reOrder"
                      ? reOrderQty
                      : sqoffQty
                  }
                  onChange={(e) => {
                    if (mode === "reOrder") {
                      if (parseInt(e.target.value) < 0) {
                        setReOrderQty(0);
                      } else {
                        setReOrderQty(parseInt(e.target.value));
                      }
                    } else {
                      if (parseInt(e.target.value) > parseInt(order.quantity)) {
                        setSqoffQty(order.quantity);
                      } else if (parseInt(e.target.value) < 0) {
                        setSqoffQty(0);
                      } else {
                        setSqoffQty(parseInt(e.target.value));
                      }
                    }
                  }}
                  onBlur={() => {
                    if (filteredIndex?.lot_size) {
                      const lotSize = filteredIndex?.lot_size;

                      if (mode === "reOrder") {
                        setReOrderQty((prev) => {
                          const roundedValue =
                            Math.round(prev / lotSize) * lotSize;
                          return roundedValue;
                        });
                      } else {
                        setSqoffQty((prev) => {
                          const roundedValue =
                            Math.round(prev / lotSize) * lotSize;
                          return roundedValue;
                        });
                      }
                    }
                  }}
                />
                {mode !== "all" && (
                  <Button
                    className="boot-btn"
                    size="sm"
                    variant="secondary"
                    onClick={() => {
                      if (mode === "reOrder") {
                        setReOrderQty((prev) => {
                          return parseInt(prev || 0) + filteredIndex?.lot_size;
                        });
                      } else {
                        setSqoffQty((prev) => {
                          if (parseInt(prev) + 1 >= Number(order.quantity)) {
                            return order.quantity;
                          }
                          return parseInt(prev || 0) + filteredIndex?.lot_size;
                        });
                      }
                    }}
                  >
                    <span className="bi bi-plus" />
                  </Button>
                )}
              </InputGroup>
            </div>
            {/* <FormGroup className="w-2/3 flex gap-1">
                  {mode !== "all" && (
                    <Button
                      style={{ width: "max-content" }}
                      className="px-2"
                      variant="danger"
                      onClick={() =>
                        setSqoffQty((prev) => {
                          if (prev - 1 < filteredIndex?.lot_size) {
                            return filteredIndex?.lot_size;
                          }
                          return parseInt(prev || 0) - filteredIndex?.lot_size;
                        })
                      }
                    >
                      -
                    </Button>
                  )}
                  <FormControl
                    disabled={disabled || mode === "all"}
                    type="number"
                    step={1}
                    value={mode === "all" ? order?.quantity : sqoffQty}
                    name="quantity"
                    className="w-3/4 px-2"
                    onChange={(e) => {
                      if (parseInt(e.target.value) > parseInt(order.quantity)) {
                        setSqoffQty(order.quantity);
                      } else if (
                        parseInt(e.target.value) < 0
                      ) {
                        setSqoffQty(0);
                      } else {
                        setSqoffQty(parseInt(e.target.value));
                      }
                    }}
                  />
                  {mode !== "all" && (
                    <Button
                      style={{ width: "max-content" }}
                      className="px-2"
                      variant="success"
                      onClick={() =>
                        setSqoffQty((prev) => {
                          if (parseInt(prev) + 1 >= Number(order.quantity)) {
                            return order.quantity;
                          }
                          return parseInt(prev || 0) + filteredIndex?.lot_size                          ;
                        })
                      }
                    >
                      +
                    </Button>
                  )}
                </FormGroup> */}
          </Col>
        </Row>
      </Container>
      <div style={{ justifyContent: "center" }} className="flex">
        <div className="flex gap-2 justify-center mt-3">
          <div>
            <Button
              className="button-style"
              variant="primary"
              onClick={handleSquareOff}
            >
              {submitLoader ? (
                <Spinner size="sm" />
              ) : mode === "reOrder" ? (
                "Re-Order"
              ) : (
                "Square Off"
              )}
            </Button>
          </div>
          <div>
            <Button
              className="button-style"
              variant="secondary"
              onClick={onHide}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};

export default SquareOff;
