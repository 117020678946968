import React, { useState } from "react";
import NavBar from "../../components/navbar/NavBar";
import * as style from "./style";
import AddBrokerTable from "./AddBrokerTable";

const AddBroker = () => {
  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  const closePopup = (e) => {
    if (e.target.classList.contains("overlay")) {
      setPopupVisible(false);
    }
  };
  return (
    <>
      <style.Container>
        <style.ButtonContainer>
          <style.Button onClick={togglePopup}>
            <style.PlusIcon/>
            Add Broker
          </style.Button>
        </style.ButtonContainer>
        <style.Overlay
          className="overlay"
          visible={popupVisible}
          onClick={closePopup}
        >
          <style.Popup className="popup">
            <style.PopupHeader>Add Broker</style.PopupHeader>
            <style.ColumnWrapper>
              <div>
                <style.UserTextWrapper>
                  <style.UserText>Name</style.UserText>
                </style.UserTextWrapper>
                <div>
                  <style.UserInput
                    type="text"
                    name="userName"
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.userName}
                  />
                  {/* {formik.touched.userName && formik.errors.userName && (
                <div style={{ color: "red" }}>{formik.errors.userName}</div>
              )} */}
                </div>
              </div>

              <div>
                <style.UserTextWrapper>
                  <style.UserText>Identifier</style.UserText>
                </style.UserTextWrapper>
                <div>
                  <style.UserInput
                    type="text"
                    name="userName"
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.userName}
                  />
                  {/* {formik.touched.userName && formik.errors.userName && (
                <div style={{ color: "red" }}>{formik.errors.userName}</div>
              )} */}
                </div>
              </div>

              <div>
                <style.UserTextWrapper>
                  <style.UserText>Total Investments</style.UserText>
                </style.UserTextWrapper>
                <div>
                  <style.UserInput
                    type="text"
                    name="userName"
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.userName}
                  />
                  {/* {formik.touched.userName && formik.errors.userName && (
                <div style={{ color: "red" }}>{formik.errors.userName}</div>
              )} */}
                </div>
              </div>

              <div>
                <style.UserTextWrapper>
                  <style.UserText>Current Client Base</style.UserText>
                </style.UserTextWrapper>
                <div>
                  <style.UserInput
                    type="text"
                    name="userName"
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.userName}
                  />
                  {/* {formik.touched.userName && formik.errors.userName && (
                <div style={{ color: "red" }}>{formik.errors.userName}</div>
              )} */}
                </div>
                <style.SubmitButton>Create Broker</style.SubmitButton>
              </div>
            </style.ColumnWrapper>
            <style.ClosePopup className="close-popup" onClick={togglePopup} />
          </style.Popup>
        </style.Overlay>
        <AddBrokerTable />
      </style.Container>
    </>
  );
};

export default AddBroker;
