import styled from "styled-components";
import AppBar from "@mui/material/AppBar";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";

export const Container = styled(AppBar)`
  background-color: #000000 !important;
  position: fixed;
  box-shadow: none !important;
`

export const ContainerWrapper = styled.div`
  margin: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
`

export const IconButtonStyle = styled(IconButton)`
  /* height: 45px; */
  width: 40px;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const LogoImage = styled.img`
  height: 5.5rem;
`

export const LogoText = styled.a`
  margin-right: 16px;
  font-weight: 700;
  color: inherit;
  font-size: 17px;
  text-decoration: none;
`

export const CustomMenu = styled(Menu)`
  .MuiPaper-root {
    overflow: visible;
    filter: drop-shadow(0px 2px 8px rgba(0,0,0,0.32));
    margin-top: 1.5em;

    .MuiAvatar-root {
      width: 25px;
      height: 25px;
      margin-right: 0.5rem;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 14px;
      width: 10px;
      height: 10px;
      background-color: #f5f5f5;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
    }
  }
`;