import { Outlet, Navigate } from "react-router-dom";
import { getAuthToken, removeAuthToken } from "./storage";
import { jwtDecode } from "jwt-decode";

const protectedRoutes = () => {

  const data = getAuthToken({ isSession: true });

  const decodedData = data ? jwtDecode(data) : null;
  const expired = decodedData ? decodedData.exp * 1000 < Date.now() : false;

  if(expired){
    removeAuthToken({ isSession: true })
  }
  

  return data ? <Outlet /> : <Navigate to="/login" />;
};

export default protectedRoutes;
