import { Outlet, Navigate } from "react-router-dom";
import { getAuthToken } from "./storage";
import { jwtDecode } from "jwt-decode";

const AdminProtectedRoutes = () => {

  const data = getAuthToken({ isSession: true });
  const decodedData = data ? jwtDecode(data) : null;

  if (decodedData?.type !== "admin") {
    
    return <Navigate to="/dashboard" />;
  }

  return <Outlet/>;
};

export default AdminProtectedRoutes;
