import * as React from "react";
import * as style from "./style";
import moment from "moment-timezone";
import {
  getClient,
  getBrokerages,
  updateSession,
  updateClientDetails,
  getMargins,
} from "../../services/admin.service";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/loader/CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import { openToast } from "../../redux/slice/toastSlice";
import toastSlice from "../../redux/slice/toastSlice";
import EditClientModal from "./model/EditClientModal";
import SessionFormModal from "./model/SessionFormModal";
import { FaHome } from "react-icons/fa";

const ClientTable = () => {
  const [clientData, setClientData] = React.useState([]);
  const [brokerResponse, setBrokerResponse] = React.useState([]);
  const [brokerNames, setBrokerNames] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [margins, setMargins] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showSessionModal, setShowSessionModal] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const selector = useSelector((user) => user.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAdmin = selector?.type === "admin";
  const isExecutor =
    selector?.type === "admin" || selector?.type === "executor";

  const columns = [
    { id: "client_name", label: "Official Client Name", minWidth: 100 },
    {
      id: "name",
      label: `${t("clientTable.column1")}`,
      minWidth: 100,
    },
    { id: "broker", label: "Broker Name", minWidth: 100, align: "center" },
    {
      id: "capital",
      label: `${t("clientTable.column3")}`,
      minWidth: 100,
      align: "center",
    },
    {
      id: "margin",
      label: "Margin",
      minWidth: 100,
      align: "center",
    },
    { id: "reg_email", label: "Reg E-mail", minWidth: 100, align: "center" },
    {
      id: "session_token",
      label: "Session Token",
      minWidth: 120,
      align: "center",
    },
    // isAdmin && { id: "api_key", label: "API Key", minWidth: 100, align: "center" },
    // isAdmin && { id: "api_key_expiration", label: "API Key Expiration", minWidth: 100, align: "center" },
    // isAdmin && { id: "api_secret", label: "API Secret", minWidth: 100, align: "center" },
    // { id: "status", label: "Status", minWidth: 100, align: "center" },
    {
      id: "last_updated",
      label: "Last Updated",
      minWidth: 100,
      align: "center",
    },
    { id: "status", label: "Status", minWidth: 120, align: "center" },
    isAdmin && {
      id: "action",
      label: "Action",
      minWidth: 100,
      align: "center",
    },
  ].filter(Boolean);

  const createData = (
    id,
    client_name,
    name,
    brokerage_id,
    capital,
    margin,
    registered_email,
    session_token,
    api_key,
    api_key_expiration,
    api_secret,
    last_updated,
    status,
    reg_pass
  ) => {
    return {
      id,
      client_name,
      name,
      brokerage_id,
      capital,
      margin,
      registered_email,
      session_token,
      api_key,
      api_key_expiration,
      api_secret,
      last_updated,
      status,
      reg_pass,
    };
  };

  const truncateText = (text, limit) => {
    return text?.length > limit ? `${text?.substring(0, limit)}...` : text;
  };

  React.useEffect(() => {
    const brokerNameMapping = brokerResponse?.reduce((acc, broker) => {
      acc[broker?._id] = broker?.name;
      return acc;
    }, {});
    setBrokerNames(brokerNameMapping);
  }, [brokerResponse]);

  const handleEdit = React.useCallback((event, row) => {
    event.stopPropagation();
    setCurrentRow(row);
    setShowModal(true);
  }, []);

  const handleSubmit = React.useCallback(
    async (values, { setSubmitting }) => {
      try {
        const currencyPattern = /^(\d+(\.\d+)?([cClLtT])?|^\d+(\.\d+)?|^$)$/;

        if (!currencyPattern.test(values?.capital)) {
          return dispatch(
            openToast({ message: "Enter the valid capital", type: "error" })
          );
        }

        if (values?.capital) {
          const lastChar = values.capital.toString().slice(-1);
          if (["c", "l", "t"].includes(lastChar.toLowerCase())) {
            values.capital =
              values.capital.slice(0, -1) + lastChar.toUpperCase();
          }
        }
        setLoader(true);
        const { success, message } = await updateClientDetails({
          id: currentRow.id,
          brokerage_id: currentRow.brokerage_id,
          session_token: values.session_token,
          api_key: values.api_key,
          api_key_expiration: formatSendDate(values.api_key_expiration),
          capital: values.capital,
          margin: values.margin,
          api_secret: values.api_secret,
          status: values.status,
          display_name: values.name,
          official_email: values.registered_email,
          official_pass: values.reg_pass,
        });

        if (success) {
          setLoader(false);
          setShowModal(false);
          setSubmitting(false);
          // dispatch(openToast({ message: message, type: "success" }));
          fetchData();
        } else {
          setLoader(false);
          setShowModal(false);
          setSubmitting(false);
          dispatch(openToast({ message: message, type: "error" }));
        }
      } catch (error) {
        console.error("error: ", error);
        setLoader(false);
        setShowModal(false);
        setSubmitting(false);
        dispatch(openToast({ message: "Something went wrong", type: "error" }));
      }
    },
    [currentRow]
  );

  const fetchMarginData = React.useCallback(async () => {
    try {
      const { success, result, message } = await getMargins();
      if (success) {
        const zerodhaMargins = result.filter(
          (margin) => margin.broker === "Zerodha"
        );
        setMargins(zerodhaMargins);
      } else {
        dispatch(toastSlice({ message, type: "error" }));
      }
    } catch (error) {
      dispatch(toastSlice({ message: "Something went wrong", type: "error" }));
    }
  }, [dispatch]);

  React.useEffect(() => {
    fetchMarginData();
  }, [fetchMarginData]);

  const updatedClientData = React.useMemo(() => {
    if (!margins?.length || !clientData?.length) return clientData;

    return clientData.map((client) => {
      const zerodhaMargin = margins.find(
        (margin) =>
          margin.name === client.display_name &&
          margin.official_name === client.official_name
      );
      return zerodhaMargin
        ? {
            ...client,
            margin: parseFloat(zerodhaMargin?.aval_margin).toFixed(2),
          }
        : client;
    });
  }, [margins, clientData]);

  const handleSessionSubmit = React.useCallback(
    async (values, { setSubmitting }) => {
      try {
        setLoader(true);
        const responce = await updateSession({
          id: currentRow.id,
          session_token: values.session_token,
          margin: values.margin,
        });

        if (responce?.success) {
          setLoader(false);
          setShowSessionModal(false);
          setSubmitting(false);
          // dispatch(openToast({ message: responce?.message, type: "success" }));
          fetchData();
        } else {
          setLoader(false);
          setShowSessionModal(false);
          setSubmitting(false);
          dispatch(openToast({ message: responce?.message, type: "error" }));
        }
      } catch (error) {
        console.error("error: ", error);
        setLoader(false);
        setShowSessionModal(false);
        setSubmitting(false);
        dispatch(openToast({ message: "Something went wrong", type: "error" }));
      }
    },
    [currentRow]
  );

  const formatSendDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const formatDateWithTime = (dateString) => {
    if (!dateString) return "";
    const date = moment(dateString).format("DD-MMM-YY hh:mm A");
    return date;
  };

  const handleEditSessionToken = (e, row) => {
    setCurrentRow(row);
    setShowSessionModal(true);
  };

  const rows = React.useMemo(() => {
    const newRows = [];
    updatedClientData?.map((user) => {
      let row = createData(
        user._id,
        user.official_name,
        user.display_name,
        user.brokerage_id,
        user.capital,
        user.margin,
        user?.official_email,
        user.session_token,
        user.api_key,
        formatSendDate(user.api_key_expiration),
        user.api_secret,
        formatDateWithTime(user.session_token_updated_at),
        user.status,
        user.official_pass
      );
      newRows.push(row);
    });
    return newRows;
  }, [updatedClientData]);

  const fetchData = async () => {
    const clientResponse = await getClient();
    const brokers = await getBrokerages();
    setBrokerResponse(brokers?.result);
    setClientData(clientResponse?.result);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handelClickAddClient = () => {
    navigate("/add-client");
  };

  const handelClickHome = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <style.Container>
        <style.HeaderWrapper>
          <div>
            <style.Heading>Client Profiles</style.Heading>
          </div>
          <div className="flex gap-2 items-center">
            <button
              className="items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
              onClick={handelClickHome}
            >
              <div className="flex items-center justify-center">
                <div>
                  <FaHome className="mr-1 align-middle" />
                </div>
                <div>Home</div>
              </div>
            </button>
            {isAdmin && (
              // <style.Button onClick={handelClickAddClient}>
              //   <style.PlusIcon />
              //   Add Client
              // </style.Button>
              <button
                className="flex items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
                onClick={handelClickAddClient}
              >
                <style.PlusIcon className="mr-1 align-middle" />
                Add Client
              </button>
            )}
          </div>
        </style.HeaderWrapper>

        <div>
          {loading ? (
            <style.LoaderWrapper>
              <CustomLoader />
            </style.LoaderWrapper>
          ) : (
            <style.TableWrapper>
              <div className="ps-cl-container">
                <table className="margin-table">
                  <thead className="margin-thead table-head">
                    <tr className="margin-tr">
                      {columns.map((column, index) => (
                        <th
                          className="table-head margin-th"
                          key={index}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column?.align,
                          }}
                        >
                          {column?.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="margin-tbody">
                    {rows.map((row, index) => {
                      return (
                        <tr key={index} className="margin-tr">
                          {columns?.map((column) => {
                            const value = row[column?.id];
                            return (
                              <td
                                key={column.id}
                                className="max-w-[200px] overflow-hidden overflow-ellipsis whitespace-nowrap p-2 all-normal-text"
                                align={column?.align}
                              >
                                {
                                  // column.id === "capital"
                                  // column.id === "api_key" ||
                                  // column.id === "api_key_expiration" ||
                                  // column.id === "api_secret"
                                  column.id === "name" ? (
                                    <div>
                                      {truncateText(value, 20)}{" "}
                                      {/* <Badge pill variant="primary">
                                      {brokerNames[row.broker_id]}
                                    </Badge> */}
                                    </div>
                                  ) : column.id === "broker" ? (
                                    <div className="flex gap-1">
                                      <div>{brokerNames[row.brokerage_id]}</div>
                                    </div>
                                  ) : column.id === "reg_email" ? (
                                    <div className="flex flex-row-reverse gap-1">
                                      <div>
                                        {truncateText(
                                          row?.registered_email,
                                          30
                                        )}
                                      </div>
                                    </div>
                                  ) : column.id === "status" ? (
                                    <div className="flex gap-1 justify-center">
                                      <div>
                                        {truncateText(
                                          value.charAt(0).toUpperCase() +
                                            value.slice(1),
                                          20
                                        )}
                                      </div>
                                    </div>
                                  ) : column.id === "capital" ? (
                                    <div className="flex gap-1 justify-center">
                                      <div>{truncateText(value, 20)}</div>
                                    </div>
                                  ) : column.id === "session_token" ? (
                                    <div className="flex gap-1">
                                      <div>{truncateText(value, 8)}</div>
                                      {isExecutor && (
                                        <div>
                                          <style.PenIcon
                                            onClick={(e) =>
                                              handleEditSessionToken(e, row)
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  ) : column.id === "last_updated" ? (
                                    <div className="flex gap-1 justify-center">
                                      <div>{value}</div>
                                    </div>
                                  ) : column.id === "action" ? (
                                    <style.EditButton
                                      className="button-style"
                                      onClick={(event) =>
                                        handleEdit(event, row)
                                      }
                                    >
                                      Edit
                                    </style.EditButton>
                                  ) : column.id === "client_name" ? (
                                    <div className="flex gap-1">
                                      {truncateText(value, 20)}
                                    </div>
                                  ) : (
                                    truncateText(value, 20)
                                  )
                                }
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </style.TableWrapper>
          )}
        </div>

        <EditClientModal
          show={showModal}
          onHide={() => setShowModal(false)}
          initialValues={{
            session_token: currentRow?.session_token || "",
            capital: currentRow?.capital || "",
            margin: parseFloat(currentRow?.margin) || 0,
            api_key: currentRow?.api_key || "",
            api_key_expiration: currentRow?.api_key_expiration || "",
            api_secret: currentRow?.api_secret || "",
            status: currentRow?.status || "",
            client_name: currentRow?.client_name || "",
            registered_email: currentRow?.registered_email || "",
            reg_pass: currentRow?.reg_pass || "",
            name: currentRow?.name || "",
          }}
          onSubmit={handleSubmit}
          loader={loader}
        />

        <SessionFormModal
          show={showSessionModal}
          onHide={() => setShowSessionModal(false)}
          initialValues={{
            session_token: currentRow?.session_token || "",
            status: currentRow?.status || "",
            margin: parseFloat(currentRow?.margin) || 0,
          }}
          onSubmit={handleSessionSubmit}
          loader={loader}
        />
      </style.Container>
    </>
  );
};

export default ClientTable;
