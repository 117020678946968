import React from "react";
import * as style from "./style";

const CustomLoader = () => {
  return (
    <div>
      <style.TypingIndicator>
        <style.TypingCircle />
        <style.TypingCircle />
        <style.TypingCircle />
        <style.TypingShadow />
        <style.TypingShadow />
        <style.TypingShadow />
      </style.TypingIndicator>
    </div>
  );
};

export default CustomLoader;
