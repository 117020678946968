import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getIndexesThunk, getPlaceOrderIndexThunk } from "../redux/slice/indexSlice";

const InitializeBase = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndexesThunk());
    dispatch(getPlaceOrderIndexThunk());
  }, [dispatch]);

  return <Fragment />;
};

export default InitializeBase;
