import React, { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import { IoCloseOutline } from "react-icons/io5";
import "../../index.css";

const DraggableModal = ({ show, onHide, title, children }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onHide();
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [show, onHide]);

  const handleClose = (event) => {
    event.stopPropagation();
    onHide();
  };

  return (
    show && (
      <Draggable
        handle=".modal-headers"
        positionOffset={{ x: "-50%", y: "-50%" }}
        enableUserSelectHack={false}
      >
        <div ref={modalRef} className={`custom-modals ${show ? "opening" : ""}`}>
          <div className="modal-headers">
            <h5 className="modal-titles">{title}</h5>
            <button
              type="button"
              className="closes"
              onClick={handleClose}
              onTouchEnd={handleClose}
            >
              <IoCloseOutline />
            </button>
          </div>
          <div className="modal-bodies">{children}</div>
        </div>
      </Draggable>
    )
  );
};

export default DraggableModal;
