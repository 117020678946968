import React from "react";
import NavBar from "../../components/navbar/NavBar";
import Positions from "../../components/dashboard/Positions";

const Dashboard = () => {
  return (
    <div>
      {/* <NavBar /> */}
      <Positions />
    </div>
  );
};

export default Dashboard;
