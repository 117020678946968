import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Formik, Field } from "formik";
import DraggableModal from "../../../components/draggableModal/DraggableModal";

const SessionFormModal = ({
  show,
  onHide,
  initialValues,
  onSubmit,
  loader,
}) => {
  const isValid =
    initialValues?.status === "disabled" || initialValues?.status === "removed";
  return (
    <DraggableModal show={show} onHide={onHide} title="Edit Session Token">
      <div>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="sessionToken">
                <Form.Label>Session Token</Form.Label>
                <Field
                  disabled={isValid}
                  className="form-control mb-1 all-normal-text"
                  name="session_token"
                />
              </Form.Group>
              <div className="flex justify-center mt-3">
                <Button
                  className="button-style"
                  variant="primary"
                  type="submit"
                >
                  {loader ? <Spinner size="sm" /> : "Save Changes"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </DraggableModal>
  );
};

export default SessionFormModal;
