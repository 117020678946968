import styled from "styled-components";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { FaPlus } from 'react-icons/fa';
import EditIcon from "@mui/icons-material/Edit";

export const Container = styled.div`
  height: calc(100vh - 88px);
  margin-top: 88px;
`

export const HeaderWrapper = styled.div`
  padding: 1.5% 7%;
  display: flex;
  justify-content: space-between;
`

export const Heading = styled.p`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 500;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  height: 42px;
  padding: 10px 12px;
  font-size: 16px;
  background-color: #1e1e1e;
  color: white;
  border: none;
  cursor: pointer;
  margin-bottom: 0px !important;

  &:focus {
    outline: none;
  }
`;

export const PlusIcon = styled(FaPlus)`
  vertical-align: middle;
  margin-right: 5px;
`;

export const PenIcon = styled(EditIcon)`
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
  width: 18px !important;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 7%;
  box-shadow: none !important;
`

export const Rows = styled(TableRow)`
  cursor: pointer;
  height: 75px;
`

export const Cells = styled(TableCell)`
  font-size: 16px !important;
  font-weight: 600;
`

export const UserInput = styled.input`
  height: 30px;
  padding-left: 10px;
  font-size: 16px;
  background-color: whitesmoke;
  border: 1px solid #ccc;

  &:focus {
    outline: none;
  }
`;

export const BodyCellWrapper = styled.div`
  display: flex;
  justify-content: center;
`
export const BodyCells = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: calc(80vh - 67px);
  align-items: center;
`

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: 27px;
  padding: 8px;
  width: 60px;
  font-size: 16px;
  background-color: #1e1e1e;
  color: white;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const EditTableButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  height: 27px;
  padding: 8px;
  width: 60px;
  font-size: 16px;
  background-color: #1e1e1e;
  color: white;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
