import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    /* background: radial-gradient(#653d84, #332042); */
    background-color: #24242c;
    position: relative;
    overflow: hidden;
`

export const LoginBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    height: 100%;
    width: 100%;
    background: #fff;
    /* border-radius: 10px; */
    box-shadow: 1px 4px 22px -8px #0004;
    overflow: hidden;

  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    overflow: hidden;
  }
`
export const LoginBoxLeft = styled.div`
  width: 60%;
  height: 100%;
  background: linear-gradient(-45deg, #dcd7e0, #fff);

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`

export const LoginBoxLeftText = styled.h3`
  text-align: center;
  /* margin-bottom: 40px; */
  color: #24242c;
`

export const LoginBoxRight = styled.div`
  width: 59%;
  height: 100%;  
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),url(https://res.cloudinary.com/dz2ohx4bs/image/upload/v1714804838/LoginImage/qvxgoff8epi9ream6yr2.jpg);
  color: #fff;
  position: relative;
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: 767px) {
    background-position: center;
    background-size: cover;
    width: 100%;
  }

  @media screen and (max-width: 950px) {
    background-position: center;
    background-size: cover;
  }

  @media screen and (max-width: 780px) {
    background-position: center;
    background-size: cover;
  }
`

export const ContactContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`

export const SiginContainer = styled.div`
    width: 100%;
`

export const GoogleButton = styled.button`
    all: unset;
    margin: auto;
    width: fit-content;
    height: 52px;
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: all 300ms ease-in-out;
    display: flex;
    justify-content: space-between; 
    &:hover{
        box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
        cursor: pointer;
    }
    &:active{
        background: #3367d6;
    }
`

export const IconWrapper = styled.div`
    width: 50px;
    height: 52px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;
`

export const GoogleButtonText = styled.p`
    display: flex;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.2px;
    margin-left: 1em;
    margin-right: 1em;
    align-items: center;
`

export const RightContainer = styled.div`
  height: 92%;
  position: relative;
  transform: translate(0%, 45%);
`

export const RightContainerTextH2 = styled.h2`
  /* display: block; */
  width: 100%;
  text-align: center;
  font-size: 50px;
  font-weight: 500;

  @media screen and (max-width: 950px) {
    font-size: 40px;
  }

  @media screen and (max-width: 780px) {
    font-size: 40px;
  }
`

export const RightContainerTextH5 = styled.h2`
  /* display: block; */
  width: 100%;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
`