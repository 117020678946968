import styled from "styled-components";

export const HeadingWrapper = styled.div`
  padding: 1.5% 7%;
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 10px; */
  /* @media only screen and (max-width: 767px) {
    margin-left: 3%;
  } */
`;


export const Heading = styled.h4`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px !important;
`;

export const UserContainers = styled.div`
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
`;

export const Dropdown = styled.select`
  padding: 10px;
  border: 1px solid #ccc;
  width: 100%;
  font-size: 13px;
  &:focus{
    outline: none;
  }
`
;

export const Container = styled.div`
  height: calc(95vh - 90px);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  /* margin-bottom: 10px; */

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 15px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 40%;

  @media screen and (max-width: 859px) and (min-width: 768px) {
    width: 40%;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 859px) and (min-width: 768px) {
    width: 100%;
  }
`;

export const UserText = styled.div`
  font-size: 13px;
  font-weight: 500;
  width: 120px;
`;

export const UserTypeText = styled.div`
  font-size: 13px;
  font-weight: 500;
  width: 95px;
`;

export const UserEmailText = styled.div`
  font-size: 13px;
  font-weight: 500;
  width: 97px;
`;

export const UserRightText = styled.div`
  font-size: 13px;
  font-weight: 500;
  width: 106px;

  @media screen and (max-width: 859px) and (min-width: 768px) {
    width: 90px;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  @media screen and (max-width: 859px) and (min-width: 768px) {
    width: 100%;
  }
`;

export const UserInput = styled.input`
  height: 30px;
  padding-left: 10px;
  font-size: 13px;
  background-color: whitesmoke;
  border: 1px solid #ccc;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 859px) and (min-width: 768px) {
    width: 100%;
  }

  @media screen and (min-width: 860px) {
    width: 100%;
  }

  @media screen and (max-width: 760px) and (min-width: 369px) {
    width: 96%;
  }

  @media screen and (max-width: 350px) and (min-width: 304px) {
    width: 94%;
  }
`;

export const UserCheckbox = styled.input`
  height: 20px;
  width: 20px;
  vertical-align: middle;
  padding-left: 10px;
  font-size: 16px;
  background-color: whitesmoke;
  border: 1px solid #ccc;
  cursor: pointer;

  &:focus {
    outline: none;
  }

`;

export const ApiInput = styled.input`
  height: 42px;
  padding-left: 10px;
  font-size: 16px;
  background-color: whitesmoke;
  border: 1px solid #ccc;

  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.label`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-40%);
  cursor: pointer;
`;

export const UserSelect = styled.select`
  height: 30px;
  padding-left: 10px;
  font-size: 13px;
  background-color: whitesmoke;
  border: none;
  border: 1px solid #ccc;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 859px) and (min-width: 768px) {
    width: 100%;
  }

  @media screen and (min-width: 860px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
  @media screen and (max-width: 760px) and (min-width: 369px) {
    width: 96%;
  }
`;

export const CustomOption = styled.option`
  color: black;
  background: white;
  font-weight: small;
  display: flex;
  white-space: pre;
  min-height: 20px;
  padding: 0px 2px 1px;

  &:hover {
    background-color: red;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  @media screen and (max-width: 859px) and (min-width: 768px) {
    width: 45%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const Tab = styled.div`
  display: flex;
  gap: 1px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TabButton = styled.button`
  float: left;
  /* height: 35px; */
  vertical-align: middle;
  padding: 8px;
  font-size: 13px;
  color: white;
  border: none;
  cursor: pointer;
  background-color: #777771;
  color: white;

  &:focus {
    outline: none;
  }
  &.active {
    background-color: #e27a3b;
  }
`;

export const RightUserInput = styled.input`
  width: 100%;
  height: 30px;
  padding-left: 10px;
  font-size: 13px;
  background-color: whitesmoke;
  border: 1px solid #ccc;

  &:focus {
    outline: none;
  }
`;

export const TabContent = styled.div`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  flex-direction: column;
  padding: 6px 0px;
  border-top: none;
  margin-top: 20px;
  gap: 20px;
`;

export const SubmitButton = styled.button`
  width: 70%;
  /* height: 42px; */
  font-size: 16px;
  background-color: #1e1e1e;
  padding: 8px;
  color: white;
  border: none;
  margin-top: 3rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ApiInputWrapper = styled.div`
  width: 70%;
`;

export const InputContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: whitesmoke;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #ccc;
  /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075); */
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border-right: 1px solid #ccc;
  border-left: none;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
  /* caret-color: rgb(255, 81, 0); */
  background-color: whitesmoke;
`;

export const Border = styled.div`
  /* height: 40%; */
  width: 1.3px;
  background-color: rgb(223, 223, 223);
`;

export const EyeButton = styled.button`
  padding: 0px 15px 0px 12px;
  border: none;
  background-color: transparent;
  height: 30px;
  cursor: pointer;
  transition-duration: 0.3s;

  &:hover {
    background-color: rgb(255, 230, 230);
    transition-duration: 0.3s;
  }
`;

export const FormikError = styled.div`
  color: #FF0000;
  font-size: 13px;
`
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: calc(90vh - 67px);
  align-items: center;
`