import React, { useState } from "react";
import { Button, Form, Spinner, InputGroup } from "react-bootstrap";
import { Formik, Field } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import DraggableModal from "../../../components/draggableModal/DraggableModal";

const EditClientModal = ({ show, onHide, initialValues, onSubmit, loader }) => {
  const [showApiKey, setShowApiKey] = useState(false);
  const [showApiSecret, setShowApiSecret] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const toggleShowApiKey = () => setShowApiKey((prev) => !prev);
  const toggleShowApiSecret = () => setShowApiSecret((prev) => !prev);
  const toggleShowPass = () => setShowPass((prev) => !prev);

  const isValid =
    initialValues?.status === "disabled" || initialValues?.status === "removed";
  return (
    <DraggableModal
      show={show}
      onHide={onHide}
      title="Edit Client Info"
    >
      <div>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, setFieldValue, values }) => (
            <Form className="all-normal-text" onSubmit={handleSubmit}>
              <Form.Group controlId="client_name">
                <Form.Label>Official Client Name</Form.Label>
                <Field
                  className="form-control mb-1 all-normal-text"
                  name="client_name"
                  disabled
                />
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>Display Name</Form.Label>
                <Field
                  className="form-control mb-1 all-normal-text"
                  name="name"
                />
              </Form.Group>
              <Form.Group controlId="registered_email">
                <Form.Label>Reg E-mail</Form.Label>
                <Field
                  className="form-control mb-1 all-normal-text"
                  name="registered_email"
                />
              </Form.Group>
              <Form.Group controlId="reg_pass">
                <Form.Label>Reg Password</Form.Label>
                <InputGroup className="mb-1">
                  <Field
                    className="form-control all-normal-text"
                    name="reg_pass"
                    type={showPass ? "text" : "password"}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={toggleShowPass}
                  >
                    {showPass ? <FaEye /> : <FaEyeSlash />}
                  </Button>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="capital">
                <Form.Label>Capital</Form.Label>
                <Field
                  type="text"
                  className="form-control mb-1 all-normal-text"
                  name="capital"
                />
              </Form.Group>
              <Form.Group controlId="margin">
                <Form.Label>Margin</Form.Label>
                <Field
                  type="number"
                  className="form-control mb-1 all-normal-text"
                  name="margin"
                />
              </Form.Group>
              <Form.Group controlId="apiKey">
                <Form.Label>API Key</Form.Label>
                <InputGroup className="mb-1">
                  <Field
                    className="form-control all-normal-text"
                    name="api_key"
                    type={showApiKey ? "text" : "password"}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={toggleShowApiKey}
                  >
                    {showApiKey ? <FaEye /> : <FaEyeSlash />}
                  </Button>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="apiSecret">
                <Form.Label>API Secret</Form.Label>
                <InputGroup className="mb-1">
                  <Field
                    name="api_secret"
                    type={showApiSecret ? "text" : "password"}
                    className="form-control all-normal-text"
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={toggleShowApiSecret}
                  >
                    {showApiSecret ? <FaEye /> : <FaEyeSlash />}
                  </Button>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="apiKeyExpiration">
                <Form.Label>API Key Expiration</Form.Label>
                <Field
                  className="form-control mb-1 all-normal-text"
                  name="api_key_expiration"
                  type="date"
                />
              </Form.Group>
              <Form.Group controlId="sessionToken">
                <Form.Label>Session Token</Form.Label>
                <Field
                  disabled={isValid}
                  className="form-control mb-1 all-normal-text"
                  name="session_token"
                />
              </Form.Group>

              <Form.Group controlId="status" className="mb-1">
                <Form.Label>Status</Form.Label>
                <Field
                  as="select"
                  className="form-control mb-1 all-normal-text custom-select"
                  name="status"
                  value={values.status}
                  onChange={(e) => setFieldValue("status", e.target.value)}
                >
                  <option className="all-normal-text" value="enabled">
                    Enabled
                  </option>
                  <option className="all-normal-text" value="disabled">
                    Disabled
                  </option>
                  <option className="all-normal-text" value="removed">
                    Removed
                  </option>
                </Field>
              </Form.Group>
              <div className="flex justify-center mt-3">
                <Button
                  variant="primary"
                  className="button-style"
                  type="submit"
                >
                  {loader ? (
                    <Spinner className="all-normal-text" size="sm" />
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </DraggableModal>
  );
};

export default EditClientModal;
