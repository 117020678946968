import styled, { keyframes } from 'styled-components';

const typingCircleAnimation = keyframes`
  0% {
    top: 20px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 8px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
`;

const typingShadowAnimation = keyframes`
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
`;

export const TypingIndicator = styled.div`
  width: 100px;
  height: 50px;
  position: relative;
  z-index: 4;
`;

export const TypingCircle = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
  background-color: #000;
  left: 15%;
  transform-origin: 50%;
  animation: ${typingCircleAnimation} 0.5s alternate infinite ease;

  &:nth-child(2) {
    left: 45%;
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
  }
`;

export const TypingShadow = styled.div`
  width: 5px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 30px;
  transform-origin: 50%;
  z-index: 3;
  left: 15%;
  filter: blur(1px);
  animation: ${typingShadowAnimation} 0.5s alternate infinite ease;

  &:nth-child(4) {
    left: 45%;
    animation-delay: 0.2s;
  }

  &:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
  }
`;