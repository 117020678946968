import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { openToast } from "../../../redux/slice/toastSlice";
import { postSquareOff } from "../../../services/admin.service";
import { toast } from "react-toastify";
import DraggableModal from "../../draggableModal/DraggableModal";

const SquareOffAll = ({
  show,
  onHide,
  orders,
  broker,
  user,
  orderKey,
  fetchPositionsData,
}) => {
  const [loading, setLoading] = useState(false);

  const executeClose = async () => {
    try {
      setLoading(true);

      let allPRs = await Promise.allSettled(
        orders.map((order) => squareOffPosition(order))
      );

      let settledCount = allPRs.reduce((prev, next) => {
        if (next.status === "fulfilled") {
          return prev + 1;
        }
        return prev;
      }, 0);
      if (settledCount === orders.length) {
        toast("Squared off all positions successfully", { type: "success" });
      } else {
        toast("Squared off all positions completed with errors", {
          type: "error",
        });
      }

      setLoading(false);
      await fetchPositionsData();
      onHide();
    } catch (error) {
      toast("Some error occurred during square off all", { type: "error" });
      setLoading(false);
    }
  };

  async function squareOffPosition(order) {
    try {
      const requestData = {
        userId: user?._id,
        user_id: user?._id,
        broker,
        order,
        squareOffQty: order.quantity,
      };

      const { success, message } = await postSquareOff(requestData);
      if (success) {
        return true;
      } else {
        toast(message, { type: "error" });
        throw Error("Some error occurred");
      }
    } catch (error) {
      toast("Some error occurred", { type: "error" });
      throw Error("Some error occurred");
    }
  }

  return (
    <DraggableModal
      show={show}
      onHide={onHide}
      title={`Square Off All: ${user?.name} - ${orderKey}`}
    >
      <div className="flex justify-center items-center gap-2">
        <button
          className="btn button-style"
          onClick={executeClose}
          disabled={loading}
        >
          {loading ? <Spinner size="sm" /> : "Confirm"}
        </button>
        {!loading && (
          <button className="btn button-style" onClick={onHide}>
            Cancel
          </button>
        )}
      </div>
    </DraggableModal>
  );
};

export default SquareOffAll;
