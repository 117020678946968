import styled from "styled-components";


export const HeadingWrapper = styled.div`
  padding: 1.5% 20%;
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
  /* @media only screen and (max-width: 767px) {
    margin-left: 3%;
  } */
`;


export const Heading = styled.h4`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px !important;
`;

export const UserContainers = styled.div`
  height: calc(60vh - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 120px);
  align-items: center;
`

export const UserContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;

export const UserInput = styled.input`
  height: 30px;
  padding-left: 10px;
  font-size: 13px;
  background-color: whitesmoke;
  border: 1px solid #ccc;

  &:focus {
    outline: none;
  }
`;

export const UserTypeText = styled.div`
  font-size: 13px;
  font-weight: 500;
  width: 120px;
`;

export const UserEmailText = styled.div`
  font-size: 13px;
  font-weight: 500;
  width: 120px;
`;

export const UserRightText = styled.div`
  font-size: 13px;
  font-weight: 500;
  width: 106px;
`;

export const FormikError = styled.div`
  color: #FF0000;
  font-size: 13px !important;
`

export const UserText = styled.div`
  font-size: 13px;
  font-weight: 500;
  width: 120px;
`;

export const InputWrapper = styled.div`

`;

export const InputWrappers = styled.div`
  width: 100%;
`;

export const UserTextWrapper = styled.div`
  margin-bottom: 7px;
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const SubmitButton = styled.button`
  width: 70%;
  /* height: 42px; */
  font-size: 13px;
  background-color: #1e1e1e;
  padding: 8px;
  color: white;
  border: none;
  margin-top: 0.5rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;


export const UserSelect = styled.select`
  width: 100%;
  height: 30px;
  padding-left: 10px;
  font-size: 13px;
  color: black;
  border: none;
  border: 1px solid #ccc;
  background-color: whitesmoke !important;

  &:focus {
    outline: none;
  }
`;

