import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { SockContext } from "../../context/SocketContext";
import { useSelector } from "react-redux";
import isEmpty from "is-empty";
import { TiArrowUp, TiArrowDown } from "react-icons/ti";
import { getTickerData } from "../../services/admin.service";

const findChangePercent = (obj) => {
  return ((obj.dayChange / obj.value) * 100).toFixed(3);
};

const findChangeValue = (lastPrice, percent) => {
  return (lastPrice * percent) / 100;
};

const NavbarTicker = () => {
  const { socket } = useContext(SockContext);
  const { indexes } = useSelector((state) => state.indexes);
  const runOnce = useRef(false);
  const [prices, setPrices] = useState([]);

  const fetchTickerData = useCallback(async () => {
    try {
      const initialPrices = indexes.map(({ instrument_token }) => ({
        identifier: instrument_token,
        price: 0,
        change: 0,
      }));
      setPrices(initialPrices);

      const { success, result } = await getTickerData();
      if (success) {
        setPrices((prev) => {
          let updatedPrices = [...prev];
          Object.keys(result).forEach((key) => {
            const itemToUpdate = updatedPrices.find(
              (item) => item.identifier === String(key)
            );
            if (itemToUpdate) {
              itemToUpdate.price = parseFloat(result[key].value).toFixed(2);
              itemToUpdate.change = findChangePercent(result[key]);
            }
          });
          return updatedPrices;
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [indexes]);

  useEffect(() => {
    fetchTickerData();
  }, [fetchTickerData]);

  useEffect(() => {
    if (socket?.active) {
      socket.on("ticker", (data) => {
        try {
          data = typeof data === "string" ? JSON.parse(data) : data;
          const { identifier, last_price, change } = data;
          setPrices((prev) => {
            const updatedPrices = [...prev];
            const itemToUpdate = updatedPrices.find(
              (item) => String(item.identifier) === String(identifier)
            );
            if (itemToUpdate) {
              itemToUpdate.price = parseFloat(last_price).toFixed(2);
              itemToUpdate.change = parseFloat(change).toFixed(3);
            }
            return updatedPrices;
          });
        } catch (error) {
          console.log(error);
        }
      });

      return () => {
        socket?.off("ticker");
      };
    }
  }, [socket]);

  return (
    <div className="w-full">
      <div className="flex gap-3 w-fit-content mx-auto justify-center uppercase font-bold bg-black text-gray-300">
        {!isEmpty(prices) &&
          prices.map((item, index) => {
            const indexObj = indexes.find(
              (index) => index.instrument_token === item.identifier
            );
            return (
              <div
                className="flex flex-col items-center text-center w-44"
                key={index}
              >
                <p className="m-0">{indexObj?.name?.toUpperCase()}</p>
                <div
                  className={`${
                    parseFloat(item.change) < 0 ? "text-red" : "text-teal"
                  } flex flex-col gap-1 items-center ml-1 w-44 `}
                >
                  <div className="flex items-center">
                    <p className="text-sm mb-0 pb-0">
                      {parseFloat(item.price).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-xs mb-0 pb-0">
                      {findChangeValue(
                        parseFloat(item.price),
                        parseFloat(item.change)
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                    &nbsp; (
                    <p className="text-xs mb-0 pb-0">
                      {parseFloat(item.change).toFixed(2)}%
                    </p>
                    )
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default NavbarTicker;
