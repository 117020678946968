export const getProfitLossPercentage = (item) => {
  let profitLossPercentage = 0;
  if (item.action === "Buy")
    profitLossPercentage =
      ((parseFloat(item.ltp) - parseFloat(item.average_price)) /
        parseFloat(item.average_price)) *
      100;
  else
    profitLossPercentage =
      ((parseFloat(item.average_price) - parseFloat(item.ltp)) /
        parseFloat(item.average_price)) *
      100;

  profitLossPercentage = parseFloat(profitLossPercentage.toFixed(2));

  return {
    pnl: profitLossPercentage,
    prefix: profitLossPercentage > 0 ? "+" : "",
  };
};

export const getProfitLoss = (item) => {
  let profitLoss = 0;
  let quantity = parseFloat(item.quantity);
  if (item.action === "Buy")
    profitLoss =
      (parseFloat(item.ltp) - parseFloat(item.average_price)) * quantity;
  else
    profitLoss =
      (parseFloat(item.average_price) - parseFloat(item.ltp)) * quantity;

  return {
    pnl: parseFloat((Math.round(profitLoss * 100) / 100).toFixed(2)),
    prefix: Math.round(profitLoss * 100) / 100 > 0 ? "+" : "",
  };
};

export const getTotalPNL = (data) => {
  const positions = data?.positions || [];
  let pnl = 0;

  const filteredPositions = positions.filter(item => parseFloat(item.quantity) > 0);
  if (filteredPositions) {
    for (let item of filteredPositions) {
      pnl += getProfitLoss(item).pnl;
    }
  }

  return {
    pnl: pnl,
    prefix: pnl < 0 ? "" : "+",
  };
};

export const getPNLTotal = (items) => {
  let pnl = 0;
  if (items) {
    for (let item of items) {
      pnl += getProfitLoss(item).pnl;
    }
  }

  return {
    pnl: pnl,
    prefix: pnl < 0 ? "" : "+",
  };
}

export const getOverallPNL = (orders) => {
  let pnl = 0;
  for (let key of Object.keys(orders)) {
    pnl += getTotalPNL(orders[key]).pnl;
  }

  return {
    pnl,
    prefix: pnl < 0 ? "" : "+",
  };
};
