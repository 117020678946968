import React, { useCallback, useEffect, useState } from "react";
import * as style from "./style";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import config from "../../config";
import { login } from "../../services/auth.service";
import { useDispatch } from "react-redux";
import { setupAuth } from "../../redux/slice/authSlice";
import { setAuthToken } from "../../utils/storage";
import isEmpty from "is-empty";
import { useNavigate } from "react-router-dom";
import { openToast } from "../../redux/slice/toastSlice";
import { useTranslation } from "react-i18next";

const UserLogin = () => {
  const [responseToken, setResponseToken] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogin = useCallback(async () => {
    try {
      const { success, message, transientToken, userId, userType } = await login({
        auth_token: responseToken,
      });
      if (success) {
        // Save auth token and set up auth state
        setAuthToken(transientToken, true);
        dispatch(setupAuth({ isAuth: true, userId, type: userType }));
        // Navigate to dashboard and show success toast
        navigate("/dashboard");
        // dispatch(openToast({ message: message, type: "success" }));
      } else {
        // Show error toast
        dispatch(openToast({ message: message, type: "error" }));
      }
    } catch (error) {
      // Show error toast
      console.error('error: ', error);
      dispatch(openToast({ message: error, type: "error" }));
    }
  }, [dispatch, responseToken]);

  useEffect(() => {
    if (!isEmpty(responseToken)) {
      handleLogin();
    }
  }, [responseToken, handleLogin]);

  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_OAUTH_ID}>
      <style.Container>
        <style.LoginBox>
          <style.LoginBoxLeft>
            <style.ContactContainer>
              <style.SiginContainer>
                <div className="display-center">
                  <GoogleLogin
                    onSuccess={(token) => setResponseToken(token.credential)}
                    onError={() => console.log("Login failed")}
                  />
                </div>
              </style.SiginContainer>
            </style.ContactContainer>
          </style.LoginBoxLeft>
          <style.LoginBoxRight>
            <style.RightContainer>
              <style.RightContainerTextH2>
                {t("toolName")}
              </style.RightContainerTextH2>
              <style.RightContainerTextH5>
                {t("companySlogan")}
              </style.RightContainerTextH5>
            </style.RightContainer>
          </style.LoginBoxRight>
        </style.LoginBox>
      </style.Container>
    </GoogleOAuthProvider>
  );
};

export default UserLogin;
