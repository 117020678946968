import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "",
  message: "",
  isOpen: false,
  autoHide: true
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    openToast: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.isOpen = true;
      state.autoHide = typeof action.payload?.autoHide == 'boolean' ? action.payload?.autoHide : true
      return state;
    },
    closeToast: (state) => {
      state.message = "";
      state.isOpen = false;
      state.autoHide = true;
      return state;
    },
    forceClose: (state) => {
      state = initialState
      return state
    }
  },
});

export const { closeToast, openToast, forceClose } = toastSlice.actions;
export default toastSlice.reducer;
