import React, { useMemo } from "react";
import * as style from "./style";
import { FaHome } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../components/loader/CustomLoader";
import { t } from "i18next";
import { getAllBroker } from "../../services/admin.service";
import UpdateModal from "./UpdateModal";
import isEmpty from "is-empty";

const BrokerList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [brokerageData, setBrokerageData] = React.useState([]);
  const [brokerAccount, setBrokerRootAccount] = React.useState("");
  const [currentRow, setCurrentRow] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);

  const columns = useMemo(
    () => [
      {
        id: "name",
        label: "Broker Name",
        minWidth: 100,
        align: "left",
        tableHead: "left",
      },
      {
        id: "identifier",
        label: "Identifier",
        minWidth: 100,
        align: "center",
        tableHead: "center",
      },
      {
        id: "root_credential_account",
        label: "Credential Account",
        minWidth: 100,
        align: "center",
        tableHead: "center",
      },
      {
        id: "status",
        label: "Status",
        minWidth: 100,
        align: "center",
        tableHead: "center",
      },
    ],
    []
  );
  const fetchData = async () => {
    const clientResponse = await getAllBroker();
    setBrokerageData(clientResponse?.result || []);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleEditAccount = React.useCallback(
    (event, row) => {
      setBrokerRootAccount(
        brokerageData?.find((item) => item.identifier === row.identifier)
          ?.root_credential_account
      );
      setCurrentRow(row);
      setShowModal(true);
    },
    [brokerageData]
  );

  const createData = (
    id,
    name,
    identifier,
    root_credential_account,
    status
  ) => {
    return { id, name, identifier, root_credential_account, status };
  };

  const rows = React.useMemo(() => {
    const newRows = [];
    (brokerageData || [])?.forEach((data) => {
      let row = createData(
        data?._id,
        data?.name,
        data?.identifier,
        data?.root_credential_account.name,
        data?.status
      );
      newRows.push(row);
    });

    return newRows;
  }, [brokerageData]);

  const handelClickHome = () => {
    navigate("/dashboard");
  };
  return (
    <React.Fragment>
      <style.Container>
        <style.HeaderWrapper>
          <div>
            <style.Heading>Brokerages</style.Heading>
          </div>
          <div className="flex gap-2 items-center">
            <button
              className="flex items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
              onClick={handelClickHome}
            >
              <FaHome className="mr-1 align-middle" />
              Home
            </button>
          </div>
        </style.HeaderWrapper>
        <div>
          {loading ? (
            <style.LoaderWrapper>
              <CustomLoader />
            </style.LoaderWrapper>
          ) : (
            <style.TableWrapper>
              <div className="ps-cl-container">
                <table className="margin-table">
                  <thead className="margin-thead table-head">
                    <tr className="margin-tr">
                      {columns.map((column, index) => (
                        <th
                          className="table-head margin-th"
                          key={index}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.tableHead,
                          }}
                        >
                          {column.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="margin-tbody">
                    {rows?.map((row, index) => (
                      <tr key={index} className="margin-tr">
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <td
                              key={column.id}
                              className="whitespace-nowrap p-2 all-normal-text"
                              align={column.align}
                            >
                              {column.id === "root_credential_account" ? (
                                <div>
                                  {value?.charAt(0)?.toUpperCase() +
                                    value?.slice(1)}{" "}
                                  <style.PenIcon
                                    onClick={(e) => handleEditAccount(e, row)}
                                  />
                                </div>
                              ) : (
                                <div>{value}</div>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </style.TableWrapper>
          )}
        </div>
        {showModal && !isEmpty(currentRow) && (
          <UpdateModal
            showModal={showModal}
            setShowModal={setShowModal}
            data={brokerageData?.find(
              (item) => item.identifier === currentRow.identifier
            )}
            brokerAccount={brokerAccount}
            setBrokerRootAccount={setBrokerRootAccount}
            fetchData={fetchData}
          />
        )}
      </style.Container>
    </React.Fragment>
  );
};

export default BrokerList;
