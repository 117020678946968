import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { openToast } from "../../redux/slice/toastSlice";
import {
  cancelOrder,
  getBrokerages,
  getClient,
  getOrderbook,
} from "../../services/admin.service";
import { Table } from "react-bootstrap";
import CustomLoader from "../loader/CustomLoader";
import { capitalize } from "@mui/material";
import { FaHome } from "react-icons/fa";
import moment from "moment";

const OrderBookUser = () => {
  const { userId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(userId);
  const [userList, setUserList] = useState([]);
  const [brokers, setBrokers] = useState([]);

  const query = new URLSearchParams(location.search);
  const brokerQuery = query.get("broker");

  const fetchOrderBookForUser = useCallback(async () => {
    if (!selectedUserId || selectedUserId.length !== 24) {
      dispatch(openToast({ message: "Invalid user", type: "error" }));
      return;
    }
    setLoading(true);

    try {
      const { success, message, result } = await getOrderbook(
        selectedUserId,
        brokerQuery
      );
      if (success) {
        setData(result);
        setLoading(false);
      } else {
        dispatch(openToast({ message, type: "error" }));
        setLoading(false);
      }
    } catch (error) {
      dispatch(openToast({ message: "Some error occurred", type: "error" }));
    }
  }, [dispatch, selectedUserId, brokerQuery]);

  const fetchUser = useCallback(async () => {
    try {
      const response = await getClient();
      if (response.success) {
        setUserList(response.result);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, []);

  const fetchBroker = useCallback(async () => {
    try {
      const response = await getBrokerages();
      if (response.success) {
        setBrokers(response.result);
      }
    } catch (error) {
      console.error("Error fetching brokers:", error);
    }
  }, []);

  useEffect(() => {
    Promise.all([fetchBroker(), fetchUser()]).then(() =>
      fetchOrderBookForUser()
    );
  }, [fetchBroker, fetchUser, fetchOrderBookForUser]);

  const getBrokerNameById = useCallback(
    (brokerId) => {
      const brokerObj = brokers.find((b) => b._id === brokerId);
      return brokerObj ? brokerObj.name : "Unknown Broker";
    },
    [brokers]
  );

  const handleUserChange = useCallback(
    (e) => {
      const userId = e.target.value;
      const selectedUser = userList.find((user) => user._id === userId);

      if (selectedUser) {
        const brokerName = getBrokerNameById(
          selectedUser.brokerage_id
        ).toLowerCase();
        const brokerDataName =
          brokerName === "icici direct" ? "icici" : "zerodha";

        setSelectedUserId(userId);
        navigate(`/order-book/${userId}?broker=${brokerDataName}`);
      }
    },
    [userList, getBrokerNameById, navigate]
  );

  const renderOrderTable = (orders) => {
    console.log("orders: ", orders);
    const todayDate = moment().format("YYYY-MM-DD");
    const todayOrders = orders
      .filter((order) => moment(order.date).isSame(todayDate, "day"))
      .sort((a, b) => moment(a.date).diff(moment(b.date)));

    const handleCancelOrder = async (orderId) => {
      try {
        const { success, message } = await cancelOrder(userId, orderId);
        if (success) {
          dispatch(openToast({ message, type: "success" }));
          await fetchOrderBookForUser();
        } else {
          dispatch(openToast({ message, type: "error" }));
        }
      } catch (error) {
        dispatch(openToast({ message: "Some error occurred", type: "error" }));
      }
    };

    return (
      <table
        striped
        bordered
        className="margin-table text-center all-normal-text"
      >
        <thead className="margin-thead table-head">
          <tr className="margin-tr">
            <th className="all-normal-text margin-th">Contract Descriptor</th>
            <th className="all-normal-text margin-th">Order ID</th>
            <th className="all-normal-text margin-th">Execution Time</th>
            <th className="all-normal-text margin-th">Type</th>
            <th className="all-normal-text margin-th">Quantity</th>
            <th className="all-normal-text margin-th">Order Type</th>
            <th className="all-normal-text margin-th">SL Price</th>
            <th className="all-normal-text margin-th">Status</th>
            <th className="all-normal-text margin-th">Actions</th>
          </tr>
        </thead>
        <tbody className="margin-tbody">
          {todayOrders.map((order) => (
            <tr className="margin-tr" key={order.order_id}>
              <td className="all-normal-text">{order.contract}</td>
              <td className="all-normal-text">{order.order_id}</td>
              <td className="all-normal-text">
                {moment(order.date).format("DD-MMM-YYYY hh:mm:ss A")}
              </td>
              <td className="all-normal-text">{order.action}</td>
              <td className="all-normal-text">{order.qty}</td>
              <td className="all-normal-text">{order.order_type}</td>
              <td className="all-normal-text">{order.stoploss}</td>
              <td className="all-normal-text">{capitalize(order.status)}</td>
              <td className="all-normal-text">
                {[
                  "pending",
                  "open",
                  "open pending",
                  "open-pending",
                  "requested",
                  "r",
                  "queued",
                  "q",
                  "ordered",
                  "o",
                  "Partially Executed",
                  "p",
                  "trigger pending",
                ].includes(order.status.toLowerCase()) && (
                  <button
                    className="orderbook-cancel-btn"
                    onClick={() => handleCancelOrder(order.order_id)}
                  >
                    Cancel
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  if (loading) return <CustomLoader />;

  return (
    <div className="ob-container container dark">
      <div className="flex justify-between items-center mb-2">
        <h5 className="text-base">Order Book</h5>
        <button
          onClick={() => navigate("/dashboard")}
          className="flex items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
        >
          <FaHome className="mr-1 align-middle" />
          Home
        </button>
      </div>
      <div className="mb-4">
        <label htmlFor="userSelect" className="mr-2 all-normal-text">
          Select User:
        </label>
        <select
          id="userSelect"
          value={selectedUserId}
          onChange={handleUserChange}
          className="px-2 py-1 border rounded text-base all-normal-text custom-select-dropDowm"
        >
          {userList.map((user) => (
            <option key={user._id} value={user._id}>
              {user.display_name} - {getBrokerNameById(user.brokerage_id)}
            </option>
          ))}
        </select>
      </div>
      <div
        className="ob-user-info text-base all-normal-text"
        style={{ backgroundColor: "#eff6ff", borderBottom: "none" }}
      >
        {data?.user || "No Records Found"}{" "}
        <span className="font-semibold">
          (
          {data?.orders?.[0]?.broker?.toLowerCase() === "icici"
            ? "ICICI Direct"
            : "Zerodha"}
          )
        </span>
      </div>
      {data?.orders?.length ? (
        data.orders.map((brokerage) => (
          <div
            key={brokerage.broker.toLowerCase()}
            className="ob-user-broker all-normal-text"
          >
            {brokerage.orders.length ? (
              renderOrderTable(brokerage.orders)
            ) : (
              <div className="all-normal-text">
                {/* {brokerage?.message ===
                "argument of type 'NoneType' is not iterable"
                  ? "Incorrect `api_key` or `access_token`."
                  : brokerage?.message || ""} */}
                No Order found
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="all-normal-text">No Orders Found</div>
      )}
    </div>
  );
};

export default OrderBookUser;
