import React from "react";

const MarginDisplay = ({ data }) => {
  return (
    <table className="margin-table">
      <thead className="margin-thead table-head">
        <tr className="margin-tr">
          <th className="margin-th text-start">Client Name</th>
          <th className="margin-th text-start">Broker</th>
          <th className="margin-th text-center">Available Margins</th>
          <th className="margin-th text-center">Available Cash</th>
          <th className="margin-th text-center">Collateral</th>
          <th className="margin-th text-center">Utilised Margins</th>
          <th className="margin-th text-center">Consent Capital</th>
        </tr>
      </thead>
      <tbody className="margin-tbody">
        {data.map((item) => (
          <tr
            className={`margin-tr ${
              item.aval_margin < 150000 ? "red-row" : "green-row"
            }`}
          >
            <td>{item?.official_name?.toUpperCase()}</td>
            <td>{item?.broker}</td>
            <td className="text-right">
              {parseFloat(item?.aval_margin || 0).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td className="text-right">
              {parseFloat(item?.capital || 0).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td className="text-right">
              {parseFloat(item?.collateral || 0).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td className="text-right">
              {parseFloat(item?.used_margin || 0).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
            <td className="text-right">
              {parseFloat(item?.total_capital || 0).toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MarginDisplay;
