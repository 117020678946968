import React, { useCallback, useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import DraggableModal from "../../components/draggableModal/DraggableModal";
import { openToast } from "../../redux/slice/toastSlice";
import { useDispatch } from "react-redux";
import {
  getActiveClients,
  updateBrokerRootAccount,
} from "../../services/admin.service";
import isEmpty from "is-empty";

const UpdateModal = ({
  showModal,
  setShowModal,
  brokerAccount,
  setBrokerRootAccount,
  data,
  fetchData,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = React.useState(false);
  const [clientList, setClientList] = React.useState([]);
  const [selected, setSelected] = React.useState(brokerAccount._id);

  const handleSubmit = async (event) => {
    setLoader(true);
    event.preventDefault();
    const { success, message } = await updateBrokerRootAccount({
      broker_id: data?._id,
      root_credential_account: selected,
    });

    if (success) {
      setLoader(false);
      setShowModal(false);
      await fetchData();
    } else {
      setLoader(false);
      setShowModal(false);
      dispatch(openToast({ message: message, type: "error" }));
    }
  };

  const fetchClients = useCallback(async () => {
    try {
      const { success, message, result } = await getActiveClients(data?._id);
      if (success) {
        setClientList(result);
      } else {
        dispatch(
          openToast({
            message: message,
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        openToast({
          message: "Some error occurred while fetching client list",
          type: "error",
        })
      );
    }
  }, [dispatch, data._id]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  return (
    <DraggableModal
      title="Update Brokerage Root Account"
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <div>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Available Clients</Form.Label>
            <Form.Select
              className="all-normal-text"
              onChange={(e) => setSelected(e.target.value)}
              value={selected}
              disabled={isEmpty(clientList)}
            >
              {clientList &&
                clientList.map((client) => (
                  <option className="all-normal-text" value={client._id}>
                    {client.display_name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          <div className="flex gap-2 mt-3 justify-center">
            <Button className="button-style" variant="primary" type="submit">
              {loader ? <Spinner size="sm" /> : "Update"}
            </Button>
            <Button
              className="button-style"
              variant="secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </DraggableModal>
  );
};

export default UpdateModal;
