/* eslint-disable eqeqeq */
import React, { Fragment, useState } from "react";
import { getProfitLoss, getProfitLossPercentage } from "../../utils/positions";

const OrderDisplay = ({
  order,
  client,
  pos,
  handleShowSquareOff,
  handleShowStopLoss,
  group,
}) => {
  const [record] = useState(order);

  return (
    <Fragment>
      <tbody className="margin-tbody">
        <tr className="margin-tr">
          <td className="all-normal-text p-2 ">{record?.contract}</td>
          <td className="text-center all-normal-text p-2">{record?.action}</td>
          <td className="text-right all-normal-text p-2">{record?.quantity}</td>
          <td className="text-right all-normal-text p-2">
            {record?.action === "Sell" ? record?.cover_quantity : ""}
          </td>
          <td className="text-right all-normal-text p-2">
            {record?.action === "Buy" ? record?.cover_quantity : ""}
          </td>
          <td className="text-right all-normal-text p-2">
            {parseFloat(record?.average_price || 0).toFixed(2)}
          </td>
          <td className="text-right all-normal-text p-2">
            {parseFloat(record?.ltp || 0).toFixed(2)}
          </td>
          <td className="text-right all-normal-text p-2">
            {((record?.action === "Buy"
              ? record.ltp - record.average_price
              : record?.average_price - record?.ltp) > 0
              ? "+"
              : "") +
              (record?.action === "Buy"
                ? record?.ltp - record?.average_price
                : record?.average_price - record?.ltp
              ).toFixed(2)}
          </td>
          <td
            align="right"
            className={`${
              getProfitLossPercentage(record).pnl < 0
                ? "loss-color"
                : "profit-color"
            }
            all-normal-text p-2
                `}
          >
            {getProfitLossPercentage(record).prefix +
              getProfitLossPercentage(record).pnl.toFixed(2)}
            %
          </td>
          <td
            align="right"
            className={`${
              getProfitLoss(record).pnl < 0 ? "loss-color" : "profit-color"
            } all-normal-text p-2`}
          >
            {getProfitLoss(record).prefix +
              getProfitLoss(record).pnl.toLocaleString("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </td>
          <td className="ps-cl-sqoff text-center all-normal-text p-2">
            <span
              onClick={() =>
                handleShowStopLoss(client, record, pos.broker, group)
              }
              style={{ cursor: "pointer" }}
            >
              Stop&nbsp;Loss
            </span>
          </td>
          <td className="ps-cl-sqoff text-center all-normal-text p-2">
            <span
              onClick={() =>
                handleShowSquareOff(
                  client,
                  record,
                  pos.broker,
                  "reOrder",
                  group
                )
              }
              style={{ cursor: "pointer" }}
            >
              Reorder
            </span>
          </td>
          <td className="ps-cl-sqoff text-center all-normal-text p-2">
            <span
              onClick={() =>
                handleShowSquareOff(client, record, pos.broker, "mono", group)
              }
              style={{ cursor: "pointer" }}
            >
              Square&nbsp;Off
            </span>
          </td>
          <td className="ps-cl-sqoff text-center all-normal-text p-2">
            <span
              onClick={() =>
                handleShowSquareOff(client, record, pos.broker, "all", group)
              }
              style={{ cursor: "pointer" }}
            >
              Square&nbsp;Off&nbsp;All
            </span>
          </td>
        </tr>
      </tbody>
    </Fragment>
  );
};

export default OrderDisplay;
