import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userData } from "../../services/auth.service";

export const fetchUserData = createAsyncThunk(
  "auth/fetchUserData",
  async () => {
    try {
      const response = await userData();
      if (response.user) {
        return { isAuth: true, ...response.user };
      } else {
        return { isAuth: false, id: "", type: "" };
      }
    } catch (error) {
      return { isAuth: false, id: "", type: "" };
    }
  }
);

const initialState = {
  isAuth: false,
  userId: "",
  type: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setupAuth: (state, { payload }) => {
      state.isAuth = payload.isAuth;
      state.userId = payload.userId;
      state.type = payload.type;
    },
    revokeAuth: (state) => {
      state.isAuth = false;
      state.userId = "";
      state.type = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.isAuth = !!action?.payload?.isAuth;
      state.userId = action?.payload?.id;
      state.type = action?.payload?.type;
    });
  },
});

export const { setupAuth, revokeAuth } = authSlice.actions;
export default authSlice.reducer;
