import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openToast } from "../../redux/slice/toastSlice";
import { deleteGroup, getPositionsData } from "../../services/admin.service";
import isEmpty from "is-empty";
import PositionsClassifierNonGroup from "./PositionClassifierNonGroup";
import CustomLoader from "../loader/CustomLoader";

const Positions = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState();
  const [positions, setPositions] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupId, setGroupId] = useState("");

  const fetchPositionsData = useCallback(async () => {
    try {
      setLoading(true);
      const { success, result, message } = await getPositionsData();
      if (success) {
        setPositions(result);
        setLoading(false);
      } else {
        setLoading(false);
        dispatch(
          openToast({
            message: message,
            type: "error",
          })
        );
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        openToast({
          message: "Some error occurred",
          type: "error",
        })
      );
    }
  }, [dispatch]);

  const handleDeleteGroup = async() => {
    try {
      setIsModalOpen(true);
      const deleteData = await deleteGroup(groupId);
      
      if(deleteData.success){
        fetchPositionsData();
        setIsModalOpen(false);
      }
      else{
        dispatch(
          openToast({
            message: deleteData.message,
            type: "error",
          })
        );
      }
    } catch (error) {
      console.error('error: ', error);
      dispatch(
        openToast({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  }

  const openModal = (groupId) => {
    setIsModalOpen(true);
    setGroupId(groupId)
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchPositionsData();
  }, [fetchPositionsData]);

  return (
    <Fragment>
      <div style={{ marginTop: "135px" }} className={`ps-container ${loading && "ps-load"}`}>
        {loading ? (
          <CustomLoader />
        ) : (
          <Fragment>
            {isEmpty(positions) ? (
              <div className="ps-load">No Positions data available</div>
            ) : (
              <>
              <PositionsClassifierNonGroup
                data={positions}
                handleDeleteGroup={handleDeleteGroup}
                fetchPositionsData={fetchPositionsData}
                isModalOpen={isModalOpen}
                openModal={openModal}
                closeModal={closeModal}
              />
              </>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Positions;
