import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import authReducer from "./slice/authSlice";
import toastReducer from "./slice/toastSlice";
import indexReducer from "./slice/indexSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    toast: toastReducer,
    indexes: indexReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [],
      },
    }).concat(thunk),
});

export default store;
