import Dashboard from "./pages/dashboard/Dashboard";
import UserLogin from "./pages/login/UserLogin";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./utils/protectedRoutes";
import AdminProtectedRoutes from "./utils/AdminprotectedRoutes";
import FreeRoutes from "./utils/FreeRoutes";
import Toast from "./components/toast/SnackBar";
import AddClient from "./pages/client/addClient/AddClient";
import AddBroker from "./pages/addBroker/AddBroker";
import AddIndex from "./pages/addIndex/AddIndex";
import ClientTable from "./pages/client/ClientTable";
import "./config/i18n";
import InitializeBase from "./helpers/InitializeBase";
import SockContextWrapper from "./context/SocketContext";
import ClientMoreDetails from "./pages/client/clientMoreDetails/ClientMoreDetails";
import AddIndexPage from "./pages/addIndex/AddIndexPage";
import Orderbook from "./pages/orderbook/Orderbook";
import AllUsers from "./pages/user/AllUsers";
import NavbarTicker from "./components/ticker/NavbarTicker";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MarginInfo from "./pages/margins/MarginInfo";
import MainLayout from "./layout/MainLayout";
import AddUserForm from "./pages/user/adduser/AddUserForm";
import GroupCategory from "./pages/group/GroupCategory";
import BrokerList from "./pages/broker/BrokerList";

function App() {
  return (
    <>
      <SockContextWrapper>
        <Toast />
        <ToastContainer />
        <InitializeBase />
        <Routes>
          {/* All Protected Routes */}
          <Route element={<ProtectedRoutes />} exact>
            {/* All Admin Routes */}
            <Route path={"/"} element={<MainLayout />}>
              <Route element={<ClientTable />} path="/client-profile" />
              <Route element={<Dashboard />} path="/dashboard" />
              <Route element={<GroupCategory/>} path="/group/:clientId" />
              <Route element={<Orderbook />} path="/order-book/:userId" />
              <Route element={<AdminProtectedRoutes />} exact>
                <Route element={<AddClient />} path="/add-client" />
                <Route element={<AddUserForm />} path="/add-user" />
                <Route element={<BrokerList />} path="/brokerage" />
                <Route element={<AddBroker />} path="/add-broker" />
                <Route element={<AddIndex />} path="/index" />
                <Route element={<AddIndexPage />} path="/add-index" />
                <Route element={<ClientTable />} path="/client-profile" />
                <Route element={<AllUsers />} path="/user-profile" />
                <Route
                  element={<ClientMoreDetails />}
                  path="/client-profile/details/:id/:brokerId"
                />
                <Route element={<MarginInfo />} path="/margin-info" />
              </Route>
            </Route>
          </Route>

          {/* All Free Routes */}
          <Route element={<FreeRoutes />} exact>
            <Route element={<UserLogin />} path="/login" />
          </Route>

          {/* All Open Routes */}
          <Route element={<Navigate to={"/dashboard"} />} path="*" />
          {/* <Route element={<NavbarTicker />} path="/ticker" /> */}
        </Routes>
      </SockContextWrapper>
    </>
  );
}

export default App;
