import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import DraggableModal from "../../draggableModal/DraggableModal";
import isEmpty from "is-empty";

const ChangePositionModal = ({
  show,
  onHide,
  fromGroup,
  availableGroups,
  positions,
  onTransfer,
  getGroupName,
  toGroup,
  setToGroup,
  selectedContracts,
  setSelectedContracts,
}) => {
  const emptyPositions = (id) => {
    const findGroup = availableGroups.find((group) => group.group_id === id);
    const filterPosition =
      !isEmpty(findGroup) &&
      findGroup?.positions.filter((position) => position.quantity > 0);
    return filterPosition;
  };

  const handleTransfer = () => {
    const selectedContractEntries = Object.entries(selectedContracts).filter(
      ([contract, details]) => details.selected && details.quantity > 0
    );

    if (toGroup && selectedContractEntries.length > 0) {
      const contractsToTransfer = selectedContractEntries.map(
        ([contract, details]) => ({
          contract,
          quantity: parseInt(details.quantity),
        })
      );
      onTransfer(fromGroup, toGroup, contractsToTransfer);
    }
  };

  const handleContractSelection = (contract) => {
    setSelectedContracts((prev) => ({
      ...prev,
      [contract]: {
        selected: !prev[contract]?.selected,
        quantity: prev[contract]?.selected
          ? prev[contract]?.quantity
          : positions.find((position) => position.contract === contract)
              ?.quantity || 1,
      },
    }));
  };

  const handleQuantityChange = (contract, quantity) => {
    setSelectedContracts((prev) => ({
      ...prev,
      [contract]: {
        ...prev[contract],
        quantity: parseInt(quantity, 10),
      },
    }));
  };

  return (
    <DraggableModal show={show} onHide={onHide} title="Change Position">
      <div>
        <Form>
          <Row>
            <Col xs={6}>
              <Form.Group controlId="fromGroup">
                <Form.Label>From Group</Form.Label>
                <Form.Control
                  className="all-normal-text remove-text-box"
                  type="text"
                  value={getGroupName(fromGroup)}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="toGroup">
                <Form.Label>To Group</Form.Label>
                <Form.Control
                  as="select"
                  value={toGroup}
                  className="all-normal-text custom-select"
                  onChange={(e) => setToGroup(e.target.value)}
                >
                  <option className="all-normal-text" value="">
                    Select Group
                  </option>
                  {availableGroups?.map((group) => (
                    <option
                      className="all-normal-text"
                      key={group.group_id}
                      value={group.group_id}
                    >
                      {isEmpty(emptyPositions(group.group_id))
                        ? `${group.group_name} (empty)`
                        : group.group_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="contract">
            <Form.Label>Contracts</Form.Label>
            {positions
              ?.filter((position) => position.quantity > 0)
              ?.map((position, index) => (
                <div key={index} className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label={position.contract}
                    checked={!!selectedContracts[position.contract]?.selected}
                    onChange={() => handleContractSelection(position.contract)}
                  />
                  {selectedContracts[position.contract]?.selected && (
                    <Form.Control
                      type="number"
                      className="all-normal-text"
                      value={
                        parseInt(
                          selectedContracts[position.contract]?.quantity
                        ) || 1
                      }
                      onChange={(e) =>
                        handleQuantityChange(position.contract, e.target.value)
                      }
                      min={1}
                      placeholder="Quantity"
                    />
                  )}
                </div>
              ))}
          </Form.Group>
        </Form>
      </div>
      <div>
        <button
          className="items-center h-8 px-2 text-white bg-gray-900 mr-2 text-base border-none float-end cursor-pointer focus:outline-none button-style mb-2"
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          className="items-center h-8 px-2 text-white bg-gray-900 mr-2 text-base border-none float-end cursor-pointer focus:outline-none button-style"
          onClick={handleTransfer}
        >
          Transfer
        </button>
      </div>
    </DraggableModal>
  );
};

export default ChangePositionModal;
