import styled from "styled-components";

export const Container = styled.div`
  margin-top: 120px;
  height: calc(100vh - 120px);
  padding: 20px;
`;

export const Heading = styled.h2`
  font-size: 16px;
  margin-bottom: 0px !important;
`

export const Input = styled.input`
  height: 30px;
  padding-left: 10px;
  font-size: 13px;
  background-color: whitesmoke;
  border: 1px solid #ccc;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const Select = styled.select`
  height: 30px;
  padding-left: 10px;
  font-size: 16px;
  background-color: whitesmoke;
  border: 1px solid #ccc;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const BrokerageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BrokerageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;
`;

export const BrokerageText = styled.p`
  margin-bottom: 0px !important;
  font-size: 13px;
  font-weight: 500;
`;

export const SubmitButton = styled.button`
  background-color: #1e1e1e;
  float: left;
  height: 42px;
  padding: 10px 12px;
  font-size: 16px;
  color: white;
  border: none;
  cursor: pointer;
  color: white;
  width: 120px;

  &:focus {
    outline: none;
  }
  &.active {
    background-color: #1e1e1e;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const BadgeContainer = styled.div`
  width: 100%;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 120px);
  align-items: center;
`