import styled from "styled-components";
import Alert from '@mui/material/Alert';
import { ToastContainer } from "react-bootstrap";

export const TostBar = styled(ToastContainer)`
  margin-top: 30px;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 9999999 !important;
  @media only screen and (max-width: 599px) {
    margin-top: 52px;
  }
`

export const AlertBar = styled(Alert)`
  width: 100%;
`