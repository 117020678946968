import React, { useEffect, useState } from "react";
import * as style from "../addBroker/style";
import AddIndexTable from "./AddIndexTable";
import { getAllIndexData, getBrokerages } from "../../services/admin.service";
import CustomLoader from "../../components/loader/CustomLoader";

const AddIndex = () => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = useState([]);
  const [brokers, setBrokers] = useState([]);

  const fetchData = async () => {
    const responce = await getAllIndexData();
    const brokerages = await getBrokerages();

    const brokerIdToName = {};
    brokerages?.result.forEach((broker) => {
      brokerIdToName[broker.id] = broker.name;
    });

    // Replace broker IDs with names in index data
    responce?.result?.forEach((indexData) => {
      indexData.instrument_tracker =
        brokerIdToName[indexData.instrument_tracker];
    });

    setData(responce?.result);
    setBrokers(brokerages?.result);

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>

      {loading ? (
        <style.LoaderWrapper>
          <CustomLoader />
        </style.LoaderWrapper>
      ) : (
        <style.Container>
          <AddIndexTable data={data} brokers={brokers} fetchData={fetchData} />
        </style.Container>
      )}
    </>
  );
};

export default AddIndex;
