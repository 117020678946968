import * as React from "react";
import * as style from "./style";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon } from "@mui/material";
import { Settings } from "@mui/icons-material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import { removeAuthToken } from "../../utils/storage";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { revokeAuth } from "../../redux/slice/authSlice";
import { userData } from "../../services/auth.service";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import NavbarTicker from "../ticker/NavbarTicker";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MoneyOutlined from "@mui/icons-material/MoneyOutlined";

const NavBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = React.useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selector = useSelector((user) => user.auth);

  const isAdmin = selector?.type === "admin";

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelLogOut = () => {
    removeAuthToken({ isSession: true });
    dispatch(revokeAuth());
    navigate("/login");
    setAnchorEl(null);
  };

  const clientPage = () => {
    navigate("/client-profile");
    setAnchorEl(null);
  };

  const handelClickHome = () => {
    navigate("/dashboard");
  };

  const indexPage = () => {
    navigate("/index");
    setAnchorEl(null);
  };

  const marginPage = () => {
    navigate("/margin-info");
    setAnchorEl(null);
  };

  const userPage = () => {
    navigate("/user-profile");
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const { user } = await userData();
      setUser(user);
    };

    fetchData();
  }, []);

  return (
    <style.Container>
      <style.ContainerWrapper>
        <style.LogoWrapper onClick={handelClickHome} className="w-2/12">
          <style.LogoImage src={Logo} alt="Logo" />
          <style.LogoText>{t("toolName")}</style.LogoText>
        </style.LogoWrapper>
        <div className="w-8/12">
          <NavbarTicker />
        </div>
        <div className="w-2/12 flex justify-end">
          <Tooltip title="Account settings">
            <style.IconButtonStyle
              style={{ width: "40px", height: "40px" }}
              onClick={handleClick}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar>{user?.name?.charAt(0)}</Avatar>
            </style.IconButtonStyle>
          </Tooltip>
          <style.CustomMenu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => clientPage()}>
              <ListItemIcon>
                <Avatar fontSize="small" />
              </ListItemIcon>
              <label className="navbar-style" htmlFor="">
                {t("navBarProfile.drop1")}
              </label>
            </MenuItem>
            {isAdmin && (
              <div>
                <MenuItem onClick={() => userPage()}>
                  <ListItemIcon>
                    <AccountBoxIcon fontSize="small" />
                  </ListItemIcon>
                  <label className="navbar-style" htmlFor="">
                    Users
                  </label>
                </MenuItem>

                <MenuItem onClick={() => navigate("/brokerage")}>
                  <ListItemIcon>
                    <MoneyOutlined fontSize="small" />
                  </ListItemIcon>
                  <label className="navbar-style" htmlFor="">
                    Brokerage
                  </label>
                </MenuItem>

                <MenuItem onClick={() => indexPage()}>
                  <ListItemIcon>
                    <ReceiptLongIcon fontSize="small" />
                  </ListItemIcon>
                  <label className="navbar-style" htmlFor="">
                    {t("navBarProfile.index")}
                  </label>
                </MenuItem>
              </div>
            )}
            <MenuItem onClick={() => marginPage()}>
              <ListItemIcon>
                <InfoIcon fontSize="small" />
              </ListItemIcon>
              <label className="navbar-style" htmlFor="">
                {t("navBarProfile.drop2")}
              </label>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              <label className="navbar-style" htmlFor="">
                {t("navBarProfile.drop3")}
              </label>
            </MenuItem>
            <MenuItem onClick={() => handelLogOut()}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <label className="navbar-style" htmlFor="">
                {t("navBarProfile.drop4")}
              </label>
            </MenuItem>
          </style.CustomMenu>
        </div>
      </style.ContainerWrapper>
      {/* <NavbarSlider /> */}
    </style.Container>
  );
};

export default NavBar;
