import styled from "styled-components";
import { FaPlus } from 'react-icons/fa';
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";



export const Container = styled.div`
  margin-top: 120px;
  height: calc(100vh - 120px);
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: calc(100vh - 120px);
  align-items: center;
`

export const HeadingText = styled.h4`
  font-size: 26px;
`

export const UserText = styled.label`
  font-size: 18px;
  font-weight: 500;
`

export const UserTextWrapper = styled.div`
  margin-bottom: 7px;
`

export const UserInput = styled.input`
  width: 100%;
  height: 42px;
  padding-left: 10px;
  font-size: 16px;
  background-color: whitesmoke;
  border: none;

  &:focus{
    outline: none;
  }
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const SubmitButton = styled.button`
  width: 100%;
  height: 42px;
  padding-left: 10px;
  font-size: 16px;
  background-color: #1e1e1e;
  color: white;
  border: none;
  margin-top: 3rem;
  cursor: pointer;

  &:focus{
    outline: none;
  }
`

// PopupButton
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  height: 42px;
  padding: 10px 10px;
  font-size: 16px;
  background-color: #1e1e1e;
  color: white;
  border: none;
  margin-right: 1.2rem;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const SmallButton = styled.button`
  display: flex;
  align-items: center;
  height: 42px;
  padding: 10px 10px;
  font-size: 16px;
  background-color: #1e1e1e;
  color: white;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 27px;
  padding: 10px 10px;
  font-size: 16px;
  background-color: #1e1e1e;
  color: white;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const PlusIcon = styled(FaPlus)`
  vertical-align: middle;
  margin-right: 5px;
`;

export const EditIcon = styled(FaRegEdit)`
  vertical-align: middle;
  margin-right: 5px;
`

export const CancelIcon = styled(MdOutlineCancel)`
  vertical-align: middle;
  margin-right: 5px;
`

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  background-color: rgba(0, 0, 0, 0.8);
`;

export const Popup = styled.div`
  position: absolute;
  width: 500px;
  left: 50%;
  height: 88%;
  top: 55%;
  transform: translate(-50%, -50%);
  padding: 40px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #000000;
  color: #ffffff;
`;

export const PopupHeader = styled.h2`
  text-align: center;
`;

export const ClosePopup = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 23px;
  height: 23px;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    background-color: #fff;
    position: absolute;
    height: 1px;
    width: 24px;
    top: 14px;
    left: -10px;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {
    transform: rotate(45deg);
  }
`;

