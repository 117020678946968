import AxiosService from "axios";
import { getAuthToken } from "../utils/storage";

let isSession = true;
AxiosService.interceptors.request.use(
  (config) => {
    let session = getAuthToken({ isSession: true });
    let local = getAuthToken({ isSession: false });
    if (session && !local) {
      isSession = true;
    }
    let transientToken = session || local;
    if (transientToken && !config.url.endsWith("refresh-token")) {
      config.headers.Authorization = "Bearer "+transientToken;
    } else {
      config.headers.Authorization = "";
    }
    config.headers.TIMESTAMP = new Date().toISOString();
    config.headers.TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  },
  (error) => {
    throw error;
  }
);

export default AxiosService;
