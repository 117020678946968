import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { addComments, getUser } from "../../../services/admin.service";
import { useDispatch } from "react-redux";
import { openToast } from "../../../redux/slice/toastSlice";
import moment from "moment";
import DraggableModal from "../../draggableModal/DraggableModal";

const Commands = ({
  show,
  onHide,
  groupId,
  onCommentChange,
  comments,
  fetchComments,
}) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [user, setUser] = useState([]);

  useEffect(() => {
    if (show && groupId) {
      fetchComments();
    }
  }, [show, groupId]);

  useEffect(() => {
    const fetchData = async () => {
      const { result } = await getUser();
      setUser(result);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (comments.length > 0) {
      onCommentChange(comments.length);
    }
  }, [comments]);

  const findUserNameFromComments = (id) => {
    return user?.find((user) => user._id === id)?.name;
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    if (!comment.trim()) {
      dispatch(
        openToast({ message: "Comment cannot be empty", type: "error" })
      );
      return;
    }

    const commentData = {
      comment,
      group_id: groupId,
    };

    try {
      const response = await addComments(commentData);
      if (response.success) {
        setComment("");
        fetchComments();
      } else {
        dispatch(
          openToast({
            message: response.message,
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        openToast({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  };

  return (
    <DraggableModal show={show} onHide={onHide} title="Comments">
      <div className="chat-container">
        <div className="chat-box">
          <ul className="chat-messages">
            {comments.length > 0 ? (
              comments.map((comment) => (
                <li key={comment._id} className="chat-message all-normal-text">
                  <div className="message-header">
                    <strong>
                      {findUserNameFromComments(comment?.comment_by?.$oid)}
                    </strong>{" "}
                    <span className="message-time">
                      {moment(comment?.created_at?.$date).format(
                        "DD-MMM-YYYY hh:mm A"
                      )}
                    </span>
                  </div>
                  <div className="message-content all-normal-text">
                    {comment.comment}
                  </div>
                </li>
              ))
            ) : (
              <p className="no-comments">No comments available.</p>
            )}
          </ul>
        </div>
      </div>
      <Form onSubmit={handleCommentSubmit} className="chat-input-form">
        <Form.Group controlId="formComment" className="chat-input-group">
          <Form.Control
            type="text"
            placeholder="Enter your comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="chat-input all-normal-text"
          />
          <button
            type="submit"
            className="items-center h-8 px-2 text-white bg-gray-900 mr-2 text-base border-none cursor-pointer focus:outline-none button-style"
          >
            Send
          </button>
        </Form.Group>
      </Form>
    </DraggableModal>
  );
};

export default Commands;
