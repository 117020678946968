import React from "react";
import { Modal } from "react-bootstrap";

const DeleteModal = ({
  isModalOpen,
  openModal,
  closeModal,
  handleDeleteGroup,
}) => {
  if (!isModalOpen) return null;

  return (
    <>
      <Modal
        className="zindexformodal all-normal-text delete-modal"
        show={isModalOpen}
        onHide={closeModal}
        centered
      >
        <Modal.Header closeButton style={{ backgroundColor: "#343a40" }}>
          <Modal.Title style={{ fontSize: "16px", color: "#ffffff" }}>
            Change Position
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this group?</Modal.Body>
        <Modal.Footer>
          <button onClick={closeModal} className="px-2 h-8 bg-gray-300 mr-2">
            Cancel
          </button>
          <button
            onClick={handleDeleteGroup}
            className="items-center h-8 px-2 text-white bg-gray-900 mr-2 text-base border-none cursor-pointer focus:outline-none button-style"
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
