import React from "react";
import NavBar from "../../components/navbar/NavBar";
import OrderBookUser from "../../components/orderbook/OrderBookUser";

const Orderbook = () => {
  return (
    <div>
      <OrderBookUser />
    </div>
  );
};

export default Orderbook;
