import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as style from "./style";
import { Badge, Button, Spinner } from "react-bootstrap";
import NavBar from "../../../components/navbar/NavBar";
import {
  checkSessionToken,
  getBrokerages,
  getClient,
  updateClientDetails,
} from "../../../services/admin.service";
import { useTranslation } from "react-i18next";
import { openToast } from "../../../redux/slice/toastSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CustomLoader from "../../../components/loader/CustomLoader";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import isEmpty from "is-empty";

const ClientMoreDetails = () => {
  const [client, setClient] = useState({ name: "", brokers: [] });
  const [brokerNames, setBrokerNames] = useState({});
  const [loading, setLoading] = useState(true);
  const [visibility, setVisibility] = useState({});
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const user_id = params?.id;
  const broker_id = params?.brokerId;
  const [loadingStatusCheck, setLoadStatus] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isEmpty(user_id) || user_id.length !== 24) {
          dispatch(
            openToast({
              message: "Invalid user",
              type: "error",
            })
          );
          setLoading(false);
          return;
        }

        const [clientResponse, brokerResponse] = await Promise.all([
          getClient(),
          getBrokerages(),
        ]);
        const clientData = clientResponse?.result?.find(
          (client) => client?.id === user_id
        );

        if (clientData) {
          const filteredBrokers = clientData.brokers.filter(
            (broker) => broker.broker_id === broker_id
          );
          setClient({ ...clientData, brokers: filteredBrokers });
        }

        const brokerNameMapping = brokerResponse.result.reduce(
          (acc, broker) => {
            acc[broker.id] = broker.name;
            return acc;
          },
          {}
        );
        setBrokerNames(brokerNameMapping);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [user_id, broker_id]);

  const formik = useFormik({
    initialValues: {
      brokers:
        client.brokers.map((broker) => ({
          broker_id: broker.broker_id,
          capital: broker.capital || "",
          apiKey: broker.api_key || "",
          apiKeyExpiration: broker.api_key_expiration || "",
          apiSecret: broker.api_secret || "",
          sessionToken: broker.session_token || "",
          session_status: false,
        })) || [],
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setEditMode(false); // Exit edit mode after successful save
        for (const broker of values.brokers) {
          const response = await updateClientDetails({
            user_id: user_id,
            broker_id: broker.broker_id,
            capital: broker.capital,
            api_key: broker.apiKey,
            api_secret: broker.apiSecret,
            api_key_expiration: formatDate(broker.apiKeyExpiration),
            session_token: broker.sessionToken,
          });
          if (!response.success) {
            dispatch(openToast({ message: response.message, type: "error" }));
            return;
          }
        }
        dispatch(
          openToast({ message: "Data updated successfully", type: "success" })
        );
      } catch (error) {
        console.error("Error updating data:", error);
        dispatch(openToast({ message: "Something Went Wrong", type: "error" }));
      }
    },
  });

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const toggleVisibility = (field, index) => {
    setVisibility((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        [index]: !prevState[field]?.[index],
      },
    }));
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    formik.resetForm();
  };

  const handleValidate = async (
    broker_id,
    api_key,
    api_secret,
    session_token,
    index
  ) => {
    try {
      setLoadStatus((prev) => [...prev, broker_id]);
      const requestData = { broker_id, api_key, api_secret, session_token };

      const { success, message } = await checkSessionToken(requestData);

      if (success) {
        dispatch(openToast({ message: message, type: "success" }));
        // formik.setFieldValue(`brokers[${index}].session_status`, true);
        formik.setValues((prev) => {
          let findSet = prev.brokers.find(
            (item, idx) => String(idx) === String(index)
          );
          findSet.session_status = true;

          let resultant = [...prev.brokers];
          resultant[index] = findSet;

          return { ...prev, brokers: resultant };
        });
      } else {
        dispatch(openToast({ message: message, type: "error" }));
      }

      setLoadStatus((prev) => prev.filter((item) => item !== broker_id));
    } catch (error) {
      setLoadStatus((prev) => prev.filter((item) => item !== broker_id));
      dispatch(openToast({ message: "Something Went Wrong", type: "error" }));
    }
  };

  if (loading) {
    return (
      <>
        <style.LoaderWrapper>
          <CustomLoader />
        </style.LoaderWrapper>
      </>
    );
  }

  return (
    <>
      <style.HeadingContainer>
        <style.Heading>{client?.name}</style.Heading>
      </style.HeadingContainer>
      <form>
        {client?.brokers?.length > 0 ? (
          client.brokers.map((broker, index) => (
            <React.Fragment key={broker.broker_id}>
              <style.Container>
                <style.BrokerNameContainer>
                  <style.BrokerHeading>
                    <Badge pill bg="primary" className="text-light">
                      {brokerNames[broker.broker_id]}
                    </Badge>
                  </style.BrokerHeading>
                </style.BrokerNameContainer>
                <style.InputWrapper>
                  <style.InputText>
                    {t("clientMoreDetails.data2")}
                  </style.InputText>
                  <style.Input
                    type="number"
                    name={`brokers[${index}].capital`}
                    value={formik.values.brokers[index]?.capital}
                    onChange={formik.handleChange}
                    disabled={!editMode}
                  />
                </style.InputWrapper>

                <style.InputWrapper>
                  <style.InputText>
                    {t("clientMoreDetails.data3")}
                  </style.InputText>
                  <style.InputContainer>
                    <style.Input
                      type={visibility.apiKey?.[index] ? "text" : "password"}
                      name={`brokers[${index}].apiKey`}
                      value={formik.values.brokers[index]?.apiKey}
                      onChange={formik.handleChange}
                      disabled={!editMode}
                    />
                    <style.Border className="border" />
                    <style.EyeButton
                      type="button"
                      onClick={() => toggleVisibility("apiKey", index)}
                    >
                      {visibility.apiKey?.[index] ? <EyeSlash /> : <Eye />}
                    </style.EyeButton>
                  </style.InputContainer>
                </style.InputWrapper>

                <style.InputWrapper>
                  <style.InputText>
                    {t("clientMoreDetails.data4")}
                  </style.InputText>
                  <style.Input
                    type="date"
                    name={`brokers[${index}].apiKeyExpiration`}
                    value={formatDate(
                      formik.values.brokers[index]?.apiKeyExpiration
                    )}
                    onChange={formik.handleChange}
                    disabled={!editMode}
                  />
                </style.InputWrapper>

                <style.InputWrapper>
                  <style.InputText>
                    {t("clientMoreDetails.data5")}
                  </style.InputText>
                  <style.InputContainer>
                    <style.Input
                      type={visibility.apiSecret?.[index] ? "text" : "password"}
                      name={`brokers[${index}].apiSecret`}
                      value={formik.values.brokers[index]?.apiSecret}
                      onChange={formik.handleChange}
                      disabled={!editMode}
                    />
                    <style.Border className="border" />
                    <style.EyeButton
                      type="button"
                      onClick={() => toggleVisibility("apiSecret", index)}
                    >
                      {visibility.apiSecret?.[index] ? <EyeSlash /> : <Eye />}
                    </style.EyeButton>
                  </style.InputContainer>
                </style.InputWrapper>

                <style.InputWrapper>
                  <style.InputText>
                    {t("clientMoreDetails.data6")}
                  </style.InputText>
                  <style.InputTextWrapper>
                    <style.InputTextWrapperText>
                      {formatDate(broker.last_updated_at || "")}
                    </style.InputTextWrapperText>
                  </style.InputTextWrapper>
                </style.InputWrapper>

                <style.InputWrapper>
                  <style.InputText>
                    {t("clientMoreDetails.data7")}
                  </style.InputText>
                  <style.InputContainer>
                    <style.Input
                      type={
                        visibility.sessionToken?.[index] ? "text" : "password"
                      }
                      name={`brokers[${index}].sessionToken`}
                      value={formik.values.brokers[index]?.sessionToken}
                      onChange={formik.handleChange}
                      disabled={!editMode}
                    />
                    <style.Border className="border" />
                    <style.EyeButton
                      type="button"
                      onClick={() => toggleVisibility("sessionToken", index)}
                    >
                      {visibility.sessionToken?.[index] ? (
                        <EyeSlash />
                      ) : (
                        <Eye />
                      )}
                    </style.EyeButton>
                  </style.InputContainer>
                </style.InputWrapper>

                {/* <style.InputWrapper>
                  <style.InputText>
                    Status
                  </style.InputText>
                  <style.Select
                    name={`brokers[${index}].capital`}
                    value={formik.values.brokers[index]?.capital}
                    onChange={formik.handleChange}
                    disabled={!editMode}
                  >
                    <option value="">Select Status</option>
                    </style.Select>
                </style.InputWrapper> */}

                <style.ValidateButtonWrapper>
                  <div className="flex">
                    <Button
                      disabled={loadingStatusCheck.includes(broker.broker_id)}
                      variant="primary"
                      className="text-light mr-3"
                      onClick={() =>
                        handleValidate(
                          broker.broker_id,
                          formik.values.brokers[index]?.apiKey,
                          formik.values.brokers[index]?.apiSecret,
                          formik.values.brokers[index]?.sessionToken,
                          index
                        )
                      }
                    >
                      {loadingStatusCheck.includes(broker.broker_id) ? (
                        <Spinner size="sm" />
                      ) : (
                        "Validate Token"
                      )}
                    </Button>
                    {formik.values.brokers[index]?.session_status ? (
                      <style.IconContainer>
                        <style.ValidIcon />
                      </style.IconContainer>
                    ) : (
                      <style.IconContainer>
                        <style.UnValidIcon />
                      </style.IconContainer>
                    )}
                  </div>
                </style.ValidateButtonWrapper>
                <div className="w-full flex justify-end pb-3">
                  {editMode ? (
                    <div className="flex">
                      <style.EditButton type="submit" onClick={formik.handleSubmit}>
                        <style.EditIcon /> Save
                      </style.EditButton>
                      <style.EditButton
                        type="button"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </style.EditButton>
                    </div>
                  ) : (
                    <style.EditButton type="button" onClick={handleEditClick}>
                      Edit
                    </style.EditButton>
                  )}
                </div>
              </style.Container>
            </React.Fragment>
          ))
        ) : (
          <style.TextWrapper>{isEmpty(user_id) || user_id.length !== 24 ? "Invalid User " : "No Brokers found"}</style.TextWrapper>
        )}
      </form>
    </>
  );
};

export default ClientMoreDetails;
