import React, { useState } from "react";
import * as style from "./style";
import { useFormik } from "formik";
import { openToast } from "../../../redux/slice/toastSlice";
import { addUser } from "../../../services/admin.service";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import isEmpty from "is-empty";

const AddUserForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.userName) {
      errors.userName = "Required";
    } else if (
      !/^(?![_-])(?!.*[_-]{2})[a-zA-Z0-9_-\s]+(?<![_-])$/.test(values.userName)
    ) {
      errors.userName = "Invalid userName";
    } else if (values.userName.length > 35) {
      errors.userName = "Must be 35 characters or less";
    }

    if (!values.type) {
      errors.type = "Required";
    }

    return errors;
  };

  const initialFormValues = {
    email: "",
    userName: "",
    type: "",
    credentials: [],
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validate,
    onSubmit: async (values, { setErrors }) => {
      try {
        setLoader(true);
        const userData = {
          email: values.email,
          name: values.userName,
          type: values.type,
        };

        const response = await addUser(userData);
        if (response?.success) {
          setLoader(false);
          // Success Toast
          // dispatch(
          //   openToast({
          //     message: message,
          //     type: "success",
          //   })
          // );
          formik.resetForm();
          navigate(-1);
          // setPopupVisible(false);
        } else {
          setLoader(false);
          if (!isEmpty(response?.errors)) {
            const fieldErrors = {};
            Object.keys(response?.errors).forEach((key) => {
              fieldErrors[key] = response?.errors[key];
            });
            setErrors(fieldErrors);
          }
          // Error Toast
          dispatch(
            openToast({
              message: response?.message,
              type: "error",
            })
          );
        }
      } catch (error) {
        console.error("error: ", error);
        setLoader(false);
        // Error Toast
        dispatch(
          openToast({
            message: "Something went wrong",
            type: "error",
          })
        );
      }
    },
    enableReinitialize: true,
  });

  const handleHomeClick = () => {
    navigate("/dashboard");
  }

  return (
    <>
      <style.HeadingWrapper>
        <div>
          <style.Heading>Add User</style.Heading>
        </div>
        <div className="flex gap-2">
          <button
            className="items-center button-style h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
            onClick={()=>navigate(-1)}
          >
            <div className="flex items-center justify-center">
              <div>
                <IoMdArrowRoundBack className="mr-1 align-middle" />
              </div>
              <div>Back</div>
            </div>
          </button>

          <button
            className="items-center button-style h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
            onClick={handleHomeClick}
          >
            <div className="flex items-center justify-center">
              <div>
                <FaHome className="mr-1 align-middle" />
              </div>
              <div>Home</div>
            </div>
          </button>
        </div>
      </style.HeadingWrapper>
      <style.UserContainers>
        <form className="w-full max-w-sm">
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block input-text text-left mb-1 md:mb-0 pr-4">
                {t("clientInfo.email")}
              </label>
            </div>
            <div className="md:w-2/3">
              <div>
                <input
                  className="bg-gray-200 appearance-none input-style w-full py-2 px-2 leading-tight"
                  type="text"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>
              {formik.touched.email && formik.errors.email && (
                <style.FormikError>{formik.errors.email}</style.FormikError>
              )}
            </div>
          </div>
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label className="block input-text text-left mb-1 md:mb-0 pr-4">
                {t("clientInfo.name")}
              </label>
            </div>
            <div className="md:w-2/3">
              <div>
                <input
                  className="appearance-none w-full py-2 px-2 leading-tight input-style focus:outline-none"
                  type="text"
                  name="userName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userName}
                />
              </div>
              {formik.touched.userName && formik.errors.userName && (
                <style.FormikError>{formik.errors.userName}</style.FormikError>
              )}
            </div>
          </div>

          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block input-text text-left mb-1 md:mb-0 pr-4"
                for="inline-password"
              >
                {t("clientInfo.type")}
              </label>
            </div>
            <div className="md:w-2/3">
              <div>
                <select
                  className=" w-full px-2 leading-tight focus:outline-none input-style"
                  name="type"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.type}
                >
                  <option style={{ fontSize: "13px" }}>
                    Select {t("clientInfo.type")}
                  </option>
                  <option style={{ fontSize: "13px" }} value="admin">
                    {t("clientType.type1")}
                  </option>
                  <option style={{ fontSize: "13px" }} value="executor">
                    {t("clientType.type2")}
                  </option>
                  <option style={{ fontSize: "13px" }} value="developer">
                    {t("clientType.type4")}
                  </option>
                </select>
              </div>
              {formik.touched.type && formik.errors.type && (
                <style.FormikError>{formik.errors.type}</style.FormikError>
              )}
            </div>
          </div>

          <div className="md:flex md:items-center">
            <div className="md:w-1/3"></div>
            <div className="md:w-2/3 flex justify-center">
              <button
                type="submit"
                onClick={formik.handleSubmit}
                className="flex items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
              >
                {loader ? <Spinner size="sm" /> : "Create"}
              </button>
            </div>
          </div>
        </form>
      </style.UserContainers>
    </>
  );
};

export default AddUserForm;
